import { PacketIn } from "@/ts/business/game/server/inbound/PacketIn";
import { InboundPacketType } from "@/ts/business/game/server/inbound/InboundPacketType";
import { MessageIOContext } from "@/ts/business/api/MessageIOContext";
import { QueueState, readJsonQueueState } from "@/ts/business/game/queue/QueueState";


export class PacketInQueueState extends PacketIn {
    public queueState: QueueState | null = null;

    constructor() {
        super(InboundPacketType.QUEUE_STATE);
    }

    getQueueState(): QueueState {
        if (this.queueState === null)
            throw new Error("Packet has not been read yet");

        return this.queueState;
    }

    override readContents(
        _context: MessageIOContext,
        json: Record<string, any>,
    ): void {
        this.queueState = readJsonQueueState(json);
    }
}
