import { LobbySettings } from "@/ts/business/game/LobbySettings";
import { webGameAPI } from "@/app_util/WebGameAPI";
import { matomo } from "@/app_components/Matomo";
import { AppRouterInstance } from "next/dist/shared/lib/app-router-context.shared-runtime";
import { KnownLobbySettings } from "@/ts/business/game/KnownLobbySettings";
import { APIUser } from "@/ts/business/api/api_schema";
import { GameMode } from "@/ts/business/game/GameMode";


export async function startRemoteGame(
    router: AppRouterInstance,
    user: APIUser | null,
    settings: LobbySettings,
): Promise<void> {
    const lobbyID = settings.getLobbyID();
    if (lobbyID) {
        router.push(`/game/${lobbyID}`);
        return;
    }
    if (!(settings instanceof KnownLobbySettings))
        throw new Error("Expected known game setup settings");
    if (!settings.isRemote())
        throw new Error("Expected remote game setup settings");

    const mode = settings.getMode();
    if (mode === GameMode.ONLINE || mode === GameMode.RANKED) {
        router.push("/");
        return;
    }

    const response = await webGameAPI.createGame(
        settings.getMode(), settings.getGameSettings(), settings.getBotType(),
    );
    matomo.recordCreateLobby(settings);
    router.push(`/game/${response.lobby_id}`);
}
