import styles from "./RoyalUrButton.module.scss";
import React, { ReactNode, useCallback } from "react";
import Link from "next/link";
import { cn } from "@/ts/util/cn";


interface CTAButtonProps {
    className?: string;
    size?: "large" | "wide" | "small" | "tiny";
    style?: "cta" | "unobtrusive" | "basic" | "secondary" | "cancel";
    href?: string;
    onClick?: () => void;
    buttonType?: "submit" | "reset" | "button";
    children: ReactNode;
    title?: string;
}

export default function RoyalUrButton({
    className = "",
    size = "large",
    style = "unobtrusive",
    href,
    onClick,
    buttonType = "button",
    children,
    title,
}: CTAButtonProps) {
    const sizeClass = (
        size === "wide" ? styles.wide_button
            : (size === "large" ? styles.large_button
                    : (size === "small" ? styles.small_button : styles.tiny_button))
    );
    const styleClass = (
        style === "cta" ? styles.cta
            : (style === "basic" ? styles.basic
                    : (style === "secondary" ? styles.secondary
                            : (style === "cancel" ? styles.cancel : "")))
    );
    const styleClasses = cn(
        styles.button, className, sizeClass, styleClass,
        (size !== "small" && size !== "tiny" ? "shadow-md" : "shadow-sm"),
    );

    const wrappedOnClick = useCallback((event: React.MouseEvent<any>) => {
        if (!onClick || event.defaultPrevented)
            return;

        onClick();
        event.preventDefault();
    }, [onClick]);

    if (href) {
        return (
            <Link
                href={href}
                className={styleClasses}
                title={title}
                onClick={onClick ? wrappedOnClick : undefined}>

                {children}
            </Link>
        );
    } else {
        return (
            <button
                className={styleClasses}
                type={buttonType}
                title={title}
                onClick={onClick ? wrappedOnClick : undefined}>

                {children}
            </button>
        );
    }
}
