import { InboundPacketType } from "@/ts/business/game/server/inbound/InboundPacketType";
import { PacketIn } from "@/ts/business/game/server/inbound/PacketIn";
import { PacketInPong } from "@/ts/business/game/server/inbound/PacketInPong";
import { PacketInError } from "@/ts/business/game/server/inbound/PacketInError";
import { PacketInSetID } from "@/ts/business/game/server/inbound/PacketInSetID";
import { PacketInJoinLobby } from "@/ts/business/game/server/inbound/PacketInJoinLobby";
import { PacketInUnknownLobby } from "@/ts/business/game/server/inbound/PacketInUnknownLobby";
import { PacketInPlayerData } from "@/ts/business/game/server/inbound/PacketInPlayerData";
import { PacketInGame } from "@/ts/business/game/server/inbound/PacketInGame";
import { PacketInLobbyStatus } from "@/ts/business/game/server/inbound/PacketInLobbyStatus";
import { PacketInReaction } from "@/ts/business/game/server/inbound/PacketInReaction";
import { PacketInQueueState } from "@/ts/business/game/server/inbound/PacketInQueueState";
import { PacketInRemovedFromQueue } from "@/ts/business/game/server/inbound/PacketInRemovedFromQueue";


export function buildPacketIn(type: InboundPacketType): PacketIn {
    // General.
    if (type === InboundPacketType.PONG)
        return new PacketInPong();
    if (type === InboundPacketType.ERROR)
        return new PacketInError();
    if (type === InboundPacketType.SET_ID)
        return new PacketInSetID();

    // Matchmaking.
    if (type === InboundPacketType.QUEUE_STATE)
        return new PacketInQueueState();
    if (type === InboundPacketType.REMOVED_FROM_QUEUE)
        return new PacketInRemovedFromQueue();

    // Lobbies.
    if (type === InboundPacketType.JOIN_LOBBY)
        return new PacketInJoinLobby();
    if (type === InboundPacketType.UNKNOWN_LOBBY)
        return new PacketInUnknownLobby();
    if (type === InboundPacketType.LOBBY_STATUS)
        return new PacketInLobbyStatus();

    // Games.
    if (type === InboundPacketType.PLAYER_DATA)
        return new PacketInPlayerData();
    if (type === InboundPacketType.GAME)
        return new PacketInGame();
    if (type === InboundPacketType.REACTION)
        return new PacketInReaction();

    throw new Error("Unknown packet type: " + type.getName());
}
