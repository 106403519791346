import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.13_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.79.2/node_modules/next/font/google/target.css?{\"path\":\"src/app_components/font/interFont.ts\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"interFont\"}");
;
import(/* webpackMode: "eager", webpackExports: ["AccessibilityProvider"] */ "/app/src/app_components/accessibility/AccessibilityContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MoneytizerCMP"] */ "/app/src/app_components/cookie/MoneytizerCMP.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app_components/cookie/MoneytizerDummySettingsLink.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["MatomoProvider"] */ "/app/src/app_components/Matomo.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ReactQueryProvider"] */ "/app/src/app_components/ReactQueryProvider.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/globals.scss");
