import styles from "./LoginPrompt.module.scss";
import { PromptPopup } from "@/app_components/prompt/PromptPopup";
import RoyalUrButton from "@/app_components/generic/buttons/RoyalUrButton";
import React, { ReactNode, startTransition, useState } from "react";
import { LoginFormModal } from "@/app_components/auth/LoginForm";
import { SocialSignInButtons } from "@/app_components/auth/SocialSignInButtons";
import { OrSeparator } from "@/app_components/generic/OrSeparator";


interface LoginPromptProps {
    children?: ReactNode;
    visible: boolean;
    close: () => void;
    onNoThanks: () => void;
    title?: string;
    noThanksText?: string;
    redirectPathname?: string;
}


export function LoginPrompt({
    children, visible, close, onNoThanks, title, noThanksText, redirectPathname,
}: LoginPromptProps) {
    const [showLogin, setShowLogin] = useState(false);
    return (
        <>
            <PromptPopup
                title={title ?? "Would you like to sign in?"}
                visible={visible}
                close={close}>

                <div className={styles.desc}>
                    {children}
                </div>

                <div className={styles.buttons}>
                    <SocialSignInButtons redirectPathname={redirectPathname ?? null} />
                    <OrSeparator />

                    <RoyalUrButton
                        style="cta"
                        size="wide"
                        onClick={() => startTransition(() => {
                            close();
                            setShowLogin(true);
                        })}>

                        Sign in
                    </RoyalUrButton>

                    <RoyalUrButton
                        style="secondary"
                        size="wide"
                        href="/register">

                        Register
                    </RoyalUrButton>

                    <RoyalUrButton
                        size="small"
                        className="width-100%"
                        onClick={onNoThanks}>

                        {noThanksText ?? "No thanks"}
                    </RoyalUrButton>
                </div>
            </PromptPopup>

            <LoginFormModal
                visible={showLogin}
                close={() => setShowLogin(false)} />
        </>
    );
}
