"use client";
import styles from "@/app/page.module.scss";
import {
    APIGamePreferences, APIOptionalUser,
    APIUser,
    APIUserStats,
    toCompleteUser,
} from "@/ts/business/api/api_schema";
import {
    DefaultGamePreferencesProvider,
    useGamePreferences,
} from "@/app/(api)/api/getGamePreferences/useGamePreferences";
import Header from "@/app_components/layout/Header";
import { DisplayName } from "@/app_components/user/DisplayName";
import { HomePlayButtons } from "@/app_components/gamesetup/HomePlayButtons";
import React, { useEffect, useMemo, useState } from "react";
import { UserProvider } from "@/app_components/user/UserContext";
import { Role } from "@/ts/business/role/Role";
import { getRoleColours } from "@/app_components/icon/getRoleIcon";
import { MatchmakingControls } from "@/app_components/gamesetup/MatchmakingControls";
import { Path } from "@/ts/util/Path";
import { LobbySettings } from "@/ts/business/game/LobbySettings";
import { readPreferredSettings } from "@/app_util/local/readPreferredSettings";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { useMutation } from "@tanstack/react-query";
import { GameClientControls } from "@/ts/business/GameClientControls";
import { DeviceInfoProvider, useIsDesktop } from "@/app_components/accessibility/DeviceInfoContext";
import { startGame } from "@/app/game/startGame";
import { HomeBackground } from "@/app_components/layout/HomeBackground";
import { ArticleList } from "@/app_components/articles/ArticleList";
import { latestBlogPost } from "@/app/blog/blogPosts";
import { GameMode } from "@/ts/business/game/GameMode";
import { LeaderboardWidget } from "@/app_components/leaderboard/LeaderboardWidget";
import { KnownLobbySettings } from "@/ts/business/game/KnownLobbySettings";
import { rulesArticle } from "@/app/(articles)/(learn)/rules/rulesArticle";
import { LeaderboardType } from "@/ts/business/api/leaderboard/LeaderboardType";
import { LeaderboardPeriod } from "@/ts/business/api/leaderboard/LeaderboardPeriod";
import { LeaderboardGrouping } from "@/ts/business/api/leaderboard/LeaderboardGrouping";
import { LeaderboardMetric } from "@/ts/business/api/leaderboard/LeaderboardMetric";
import { cn } from "@/ts/util/cn";
import { DISCORD_INVITE_URL } from "@/ts/business/constants";
import { showNotification } from "@/app_util/game/notifications";
import { RandomGame } from "@/app_components/game/RandomGame";
import { SmallSocialLinks } from "@/app_components/social/SmallSocialLinks";
import { GameType } from "@/ts/business/game/GameType";


interface WelcomeH1Props {
    user: APIUser | null;
    newUserMode: boolean;
}


function WelcomeH1({ user, newUserMode }: WelcomeH1Props) {
    const { preferences } = useGamePreferences(user);
    const roles = Role.list(user);
    return (
        <>
            <h1 className={styles.title}>
                {newUserMode && (
                    <>
                        Join us in playing the
                        {" "}
                        <span className={cn(
                            styles.highlighter,
                            "shadow"
                        )}>
                            Royal Game of Ur!
                        </span>
                    </>
                )}

                {!newUserMode && (
                    <>
                        Welcome,
                        {" "}
                        <span className={styles.name}>
                            <DisplayName
                                name={user ? user.displayName : (
                                    <>
                                        play-
                                        <span className="font-italic">
                                            ur
                                        </span>
                                    </>
                                )}
                                preferences={preferences}
                                roles={roles} />

                            <span className={!user ? "font-italic" : ""}>
                                !
                            </span>
                        </span>
                    </>
                )}
            </h1>

            {newUserMode && (
                <p className={styles.subtitle}>
                    There&apos;s a reason this game has been played for over 4500 years.
                    Play a game and find out!
                </p>
            )}
        </>
    );
}


interface GreetingInternalProps {
    user: APIOptionalUser;
    stats: APIUserStats | null;
    ratings: Record<string, number> | null;
}


function GreetingInternal({ user, stats, ratings }: GreetingInternalProps) {
    const isDesktop = useIsDesktop();
    const completeUser = toCompleteUser(user);

    const router = useRouter();
    const [gameSettings, setGameSettings] = useState<KnownLobbySettings | null>(null);
    useEffect(() => {
        setGameSettings(
            readPreferredSettings(completeUser)
                .withMode(GameMode.COMPUTER)
        );
    }, [completeUser]);

    const newUserMode = (
        !user && (!gameSettings || gameSettings.equals(KnownLobbySettings.DEFAULT))
    );
    const roles = Role.list(completeUser);

    let ratingType: GameType | null = null;
    let rating: number | null = null;
    if (ratings) {
        for (const [gameTypeId, ratingValue] of Object.entries(ratings)) {
            if (ratingValue < 100)
                continue;

            if (rating === null || ratingValue > rating) {
                rating = ratingValue;
                ratingType = GameType.getByID(gameTypeId);
            }
        }
    }

    const startGameMutation = useMutation({
        mutationFn: async (settings: LobbySettings) => startGame(router, completeUser, settings),
    });
    const clientControls: GameClientControls = useMemo(() => {
        return {
            startGame: startGameMutation.mutate,
            startingGame: startGameMutation.isPending,
            startGameError: startGameMutation.error,
            clearError: startGameMutation.reset,
            resignGame: () => {
                throw new Error("resignGame is unsupported");
            },
            abortGame: () => {
                throw new Error("abortGame is unsupported");
            },
            notify: showNotification,
        };
    }, [startGameMutation]);

    return (
        <HomeBackground className={styles.greeting}>
            <Header />

            <div className={styles.greeting_content}>
                <div className={styles.greeting_main}>
                    <WelcomeH1
                        user={completeUser}
                        newUserMode={newUserMode} />

                    {!newUserMode || stats ? (
                        <div className={styles.greeting_stats}>
                            <div className="section">
                                <p>
                                    Games
                                </p>
                                <Link
                                    className={styles.count_link}
                                    href={(
                                        user && user.username
                                            ? `/u/${user.username}/games`
                                            : "/register"
                                    )}>
                                    {stats ? stats.games.toLocaleString() : "?"}
                                </Link>
                            </div>
                            <div className="section">
                                <p>
                                    {ratingType ? `${ratingType.getName()} Rating` : "Wins"}
                                </p>
                                <Link
                                    className={styles.count_link}
                                    href={(
                                        user && user.username
                                            ? (
                                                ratingType
                                                    ? `/u/${user.username}`
                                                    : `/u/${user.username}/games`
                                            ) : "/register"
                                    )}>
                                    {ratingType && rating
                                        ? rating.toFixed(0)
                                        : (stats ? stats.wins.toLocaleString() : "?")}
                                </Link>
                            </div>
                            <div className="section">
                                <p>
                                    {!user ? "Account" : "Memburship"}
                                </p>
                                <p>
                                    {user && (
                                        roles.includes(Role.MEMBUR) ? (
                                            <Link
                                                href="/memburship"
                                                style={{
                                                    color: getRoleColours(Role.MEMBUR)[1],
                                                }}>
                                                Active
                                            </Link>
                                        ) : (
                                            <Link href="/memburship">
                                                Become a Memb-
                                                <span className="font-italic">
                                                    ur
                                                </span>
                                            </Link>
                                        )
                                    )}

                                    {!user && (
                                        <Link href="/register">
                                            Create an account
                                        </Link>
                                    )}
                                </p>
                            </div>
                        </div>
                    ) : (
                        <div className="padding-top-md" />
                    )}
                    <HomePlayButtons
                        clientControls={clientControls}
                        gameSettings={gameSettings}
                        user={user}
                        newUserMode={newUserMode} />

                    {newUserMode && !isDesktop && (
                        <div className="width-100% margin-top-md">
                            <RandomGame inArticle={true} />
                        </div>
                    )}

                    {!newUserMode && (
                        <h2 className="width-100%">
                            Play Online
                        </h2>
                    )}

                    <div className={cn(
                        "width-100%",
                        newUserMode && "margin-top-md",
                    )}>
                        <MatchmakingControls
                            clientControls={clientControls}
                            getWatchLink={lobbyID => new Path(`/game/${lobbyID}`)}
                            small={newUserMode} />
                    </div>
                </div>

                {!newUserMode && (
                    <div className={styles.greeting_left}>
                        <h2>Latest News</h2>
                        <ArticleList
                            articles={[latestBlogPost]}
                            compact={true} />

                        <SmallSocialLinks
                            className="margin-top-sm" />
                    </div>
                )}

                {newUserMode && isDesktop && (
                    <div className={styles.greeting_left}>
                        <h2>Learn the Rules</h2>
                        <ArticleList
                            articles={[rulesArticle]}
                            compact={true} />
                    </div>
                )}

                {newUserMode && isDesktop && (
                    <div className={styles.greeting_right}>
                        <RandomGame
                            className="height-100%"
                            orientation="vertical" />
                    </div>
                )}

                {!newUserMode && (
                    <div className={styles.greeting_right}>
                        <h2 className="margin-bottom-0">
                            Leaderboard
                        </h2>
                        <LeaderboardWidget
                            from={0}
                            limit={newUserMode ? 5 : 10}
                            generateDisplayLeaderboards={yearMonth => [
                                new LeaderboardType(
                                    LeaderboardPeriod.ALL_TIME,
                                    yearMonth,
                                    LeaderboardGrouping.FINKEL,
                                    LeaderboardMetric.RATING,
                                ),
                                new LeaderboardType(
                                    LeaderboardPeriod.ALL_TIME,
                                    yearMonth,
                                    LeaderboardGrouping.BLITZ,
                                    LeaderboardMetric.RATING,
                                ),
                                new LeaderboardType(
                                    LeaderboardPeriod.ALL_TIME,
                                    yearMonth,
                                    LeaderboardGrouping.MASTERS,
                                    LeaderboardMetric.RATING,
                                ),
                            ]} />

                        <p className={styles.view_all_leaderboards_link}>
                            <Link href="/leaderboards">
                                View all leaderboards
                            </Link>
                        </p>

                        <p>
                            Our new rating system is in beta! Gain rating
                            by playing online ranked games.
                            {" "}
                            <a href={DISCORD_INVITE_URL}>
                                Join our Discord community.
                            </a>
                        </p>
                    </div>
                )}
            </div>
        </HomeBackground>
    );
}


interface GreetingProps {
    user: APIOptionalUser;
    stats: APIUserStats | null;
    ratings: Record<string, number> | null;
    serverDefaultPreferences: APIGamePreferences | null;
}


export function Greeting({ user, stats, ratings, serverDefaultPreferences }: GreetingProps) {
    const completeUser = toCompleteUser(user);
    return (
        <DeviceInfoProvider>
            <UserProvider user={user}>
                <DefaultGamePreferencesProvider
                    user={completeUser}
                    defaultPreferences={serverDefaultPreferences}>

                    <GreetingInternal
                        user={user}
                        stats={stats}
                        ratings={ratings} />
                </DefaultGamePreferencesProvider>
            </UserProvider>
        </DeviceInfoProvider>
    );
}
