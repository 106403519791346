import { LobbySummary } from "@/ts/business/lobby/LobbySummary";
import { GameType } from "@/ts/business/game/GameType";
import { HumanLobbyPlayer } from "@/ts/business/lobby/HumanLobbyPlayer";


export class GameTypeCounts {
    readonly finkelCount: number;
    readonly blitzCount: number;
    readonly mastersCount: number;
    readonly othersCount: number;
    readonly totalCount: number;

    constructor(
        finkelCount: number,
        blitzCount: number,
        mastersCount: number,
        othersCount: number,
    ) {
        this.finkelCount = finkelCount;
        this.blitzCount = blitzCount;
        this.mastersCount = mastersCount;
        this.othersCount = othersCount;
        this.totalCount = finkelCount + blitzCount + mastersCount + othersCount;
    }

    static countGamesBySettings(
        summaries: LobbySummary[],
    ): GameTypeCounts {
        let finkelCount = 0;
        let blitzCount = 0;
        let mastersCount = 0;
        let othersCount = 0;
        for (const summary of summaries) {
            if (summary.settings === GameType.FINKEL) {
                finkelCount += 1;
            } else if (summary.settings === GameType.BLITZ) {
                blitzCount += 1;
            } else if (summary.settings === GameType.MASTERS) {
                mastersCount += 1;
            } else {
                othersCount += 1;
            }
        }
        return new GameTypeCounts(finkelCount, blitzCount, mastersCount, othersCount);
    }

    static countPlayersBySettings(
        summaries: LobbySummary[],
    ): GameTypeCounts {
        let finkelCount = 0;
        let blitzCount = 0;
        let mastersCount = 0;
        let othersCount = 0;
        for (const summary of summaries) {
            const p1 = summary.player1;
            const p2 = summary.player2;
            const p1Human = (p1 === null || p1 instanceof HumanLobbyPlayer);
            const p2Human = (p2 === null || p2 instanceof HumanLobbyPlayer);
            const addCount = (p1Human ? 1 : 0) + (p2Human ? 1 : 0);
            if (summary.settings === GameType.FINKEL) {
                finkelCount += addCount;
            } else if (summary.settings === GameType.BLITZ) {
                blitzCount += addCount;
            } else if (summary.settings === GameType.MASTERS) {
                mastersCount += addCount;
            } else {
                othersCount += addCount;
            }
        }
        return new GameTypeCounts(finkelCount, blitzCount, mastersCount, othersCount);
    }
}
