
/**
 * The modes guide how games are provided and played.
 */
export class GameMode {
    public static readonly LOCAL = new GameMode(
        "local",
        "Local",
        "Play Locally",
        "Two players on one computer.",
        false,
    );

    public static readonly COMPUTER = new GameMode(
        "computer",
        "Computer",
        "Play the Computer",
        "Test your skills against our bots.",
        false,
    );

    public static readonly ONLINE = new GameMode(
        "online",
        "Online",
        "Play Online",
        "Get matched with an opponent.",
        true,
    );

    public static readonly RANKED = new GameMode(
        "ranked",
        "Ranked",
        "Play Ranked",
        "Get matched with an opponent.",
        true,
    );

    public static readonly FRIEND = new GameMode(
        "friend",
        "Friend",
        "Play a Friend",
        "Play with a friend over the internet.",
        true,
    );

    static values(): GameMode[] {
        return [
            GameMode.LOCAL,
            GameMode.COMPUTER,
            GameMode.ONLINE,
            GameMode.RANKED,
            GameMode.FRIEND,
        ];
    }

    private static createParsingMap(): Record<string, GameMode> {
        const map: Record<string, GameMode> = {};
        map["Local"] = GameMode.LOCAL;
        map["Computer"] = GameMode.COMPUTER;
        map["Online"] = GameMode.ONLINE;
        map["Ranked"] = GameMode.RANKED;
        map["Friend"] = GameMode.FRIEND;

        for (const value of GameMode.values()) {
            map[value.id] = value;
        }
        return Object.freeze(map);
    }

    static readonly PARSING_MAP: Record<string, GameMode> = GameMode.createParsingMap();

    private readonly id: string;
    private readonly name: string;
    private readonly playText: string;
    private readonly desc: string;
    private readonly remote: boolean;

    constructor(
        id: string,
        name: string,
        playText: string,
        desc: string,
        remote: boolean,
    ) {
        this.id = id;
        this.name = name;
        this.playText = playText;
        this.desc = desc;
        this.remote = remote;
    }

    getID(): string {
        return this.id;
    }

    getName(): string {
        return this.name;
    }

    getPlayText(): string {
        return this.playText;
    }

    getDesc(): string {
        return this.desc;
    }

    isRemote(): boolean {
        return this.remote;
    }

    static getByID(id: string): GameMode {
        for (const mode of GameMode.values()) {
            if (mode.id === id)
                return mode;
        }
        throw new Error("Unknown game mode " + id);
    }

    static getByIDOrNull(id: string | null): GameMode | null {
        if (id === null)
            return null;

        for (const mode of GameMode.values()) {
            if (mode.id === id)
                return mode;
        }
        return null;
    }

    static getByName(name: string): GameMode {
        for (const mode of GameMode.values()) {
            if (mode.name === name)
                return mode;
        }
        throw new Error("Unknown game mode " + name);
    }
}
