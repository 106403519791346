import styles from "./Leaderboard.module.scss";
import React, { ReactNode } from "react";
import { getNameColour } from "@/app_components/user/getNameColour";
import { APIPublicGamePreferences, APIUser } from "@/ts/business/api/api_schema";
import Link from "next/link";
import { LeaderboardEntry } from "@/app/(api)/api/getLeaderboard/getLeaderboard";
import { cn } from "@/ts/util/cn";


interface LeaderboardEntryProps {
    entry: LeaderboardEntry;
    isYourEntry: boolean;
    formatValue: (
        position: number,
        user: APIUser,
        preferences: APIPublicGamePreferences,
        value: number,
    ) => ReactNode;
}


export function LeaderboardEntryElem({ entry, isYourEntry, formatValue }: LeaderboardEntryProps) {
    const { position, user, preferences, value } = entry;
    return (
        <div
            className={cn(
                styles.entry,
                isYourEntry && styles.your_entry,
            )}>

            <div className={styles.position}>
                {`${(position + 1).toLocaleString()}.`}
            </div>
            <Link
                className={styles.display_name}
                href={`/u/${user.username}`}
                style={{ color: getNameColour(preferences.nameColour).colorCSS }}>

                {user.displayName}
            </Link>
            <div className={styles.value}>
                {formatValue(position, user, preferences, value)}
            </div>
        </div>
    );
}


interface LeaderboardProps {
    formatValue: (
        position: number,
        user: APIUser,
        preferences: APIPublicGamePreferences,
        value: number,
    ) => ReactNode;
    leaderboard: LeaderboardEntry[] | undefined;
    leaderboardYourEntry: LeaderboardEntry | null | undefined;
    leaderboardLoading: boolean;
    leaderboardError: Error | null;
}


export function Leaderboard({
    formatValue,
    leaderboard,
    leaderboardYourEntry,
    leaderboardLoading,
    leaderboardError,
}: LeaderboardProps) {

    if (!leaderboard || leaderboard.length === 0 || leaderboardLoading || leaderboardError) {
        let status = "The leaderboard is empty";
        if (leaderboardLoading) {
            status = "Loading...";
        } else if (leaderboardError) {
            console.error(leaderboardError);
            status = `Error loading leaderboard: ${leaderboardError.message}`;
        }
        return (
            <div className={styles.container}>
                <p className="padding-sm margin-0">
                    {status}
                </p>
            </div>
        );
    }

    const rows: ReactNode[] = [];
    let seenYourEntry: boolean = false;
    for (const entry of leaderboard) {
        const isYourEntry = (
            !!leaderboardYourEntry
            && leaderboardYourEntry.user.publicID === entry.user.publicID
        );
        seenYourEntry ||= isYourEntry;
        rows.push(
            <LeaderboardEntryElem
                key={entry.user.publicID}
                entry={entry}
                isYourEntry={isYourEntry}
                formatValue={formatValue} />
        );
    }

    if (leaderboardYourEntry && !seenYourEntry) {
        rows.push(
            <LeaderboardEntryElem
                key={`your_entry`}
                entry={leaderboardYourEntry}
                isYourEntry={true}
                formatValue={formatValue} />
        );
    }

    return (
        <div className={cn(
            styles.container,
            (!leaderboardYourEntry || leaderboardYourEntry.position !== 0)
            && styles.container_with_top_padding,
        )}>
            {rows}
        </div>
    );
}
