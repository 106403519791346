import { Seconds } from "@/ts/util/units";


export function plural(nonPlural: string, count: number) {
    if (count === 1)
        return nonPlural;

    return `${nonPlural}s`;
}

export function digitInEnglish(value: number): string {
    const english = [
        "Zero",
        "One",
        "Two",
        "Three",
        "Four",
        "Five",
        "Six",
        "Seven",
        "Eight",
        "Nine",
    ][value];
    if (english === undefined)
        throw new Error("Unknown digit " + value);

    return english;
}


export interface FormatDateOptions {
    verb?: string;
}


export function formatDate(
    date: Date,
    options?: FormatDateOptions,
    scheduleRecalc?: (seconds: Seconds) => void,
): string {

    scheduleRecalc ??= () => {};

    let verbPrefix = (options?.verb ? `${options.verb} ` : "");

    const currentTime = new Date();
    const rawSeconds = (currentTime.getTime() - date.getTime()) / 1000;
    const seconds = Math.abs(rawSeconds);
    const inFuture = (rawSeconds < 0);

    const minute = 60;
    const minutes = Math.floor(seconds / minute);
    if (minutes <= 60) {
        scheduleRecalc(minute - seconds % minute);
        if (inFuture) {
            if (minutes <= 0)
                return `${verbPrefix}in less than a minute`;

            return `${verbPrefix}in ${Math.floor(minutes)} ${plural("minute", minutes)}`;
        } else {
            if (minutes <= 0)
                return `${verbPrefix}less than a minute ago`;

            return `${verbPrefix}${Math.floor(minutes)} ${plural("minute", minutes)} ago`;
        }
    }

    const hour = 60 * minute;
    const hours = Math.floor(seconds / hour);
    if (hours <= 24) {
        scheduleRecalc(hour - seconds % hour);
        if (inFuture) {
            return `${verbPrefix}in ${hours} ${plural("hour", hours)}`;
        } else {
            return `${verbPrefix}${hours} ${plural("hour", hours)} ago`;
        }
    }

    if (inFuture || verbPrefix !== "") {
        verbPrefix += "on ";
    }
    return verbPrefix + date.toLocaleDateString(
        "en-GB", {
            year: "numeric", month: "long", day: "numeric",
        },
    );
}


export function formatInt(value: number): string {
    return value.toLocaleString("en-US");
}


const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December",
];


export function formatMonth(month: number): string {
    return months[month];
}

export function formatRatingChange(ratingChange: number): string {
    return (ratingChange >= 0 ? "+" : "-") + `${Math.abs(ratingChange).toFixed(0)}`;
}
