

export class OutboundPacketType {
    public static readonly PING = new OutboundPacketType("ping");
    public static readonly OPEN = new OutboundPacketType("open");
    public static readonly REOPEN = new OutboundPacketType("reopen");
    public static readonly JOIN_QUEUE = new OutboundPacketType("join_queue");
    public static readonly JOIN_GAME = new OutboundPacketType("join_game");
    public static readonly RESTART_GAME = new OutboundPacketType("restart_game");
    public static readonly ROLL = new OutboundPacketType("roll");
    public static readonly MOVE = new OutboundPacketType("move");
    public static readonly REMATCH = new OutboundPacketType("rematch");
    public static readonly REACTION = new OutboundPacketType("reaction");
    public static readonly REFRESH_USER = new OutboundPacketType("refresh_user");
    public static readonly RESIGN = new OutboundPacketType("resign");

    static values(): OutboundPacketType[] {
        return [
            OutboundPacketType.PING,
            OutboundPacketType.OPEN,
            OutboundPacketType.REOPEN,
            OutboundPacketType.JOIN_QUEUE,
            OutboundPacketType.JOIN_GAME,
            OutboundPacketType.RESTART_GAME,
            OutboundPacketType.ROLL,
            OutboundPacketType.MOVE,
            OutboundPacketType.REMATCH,
            OutboundPacketType.REACTION,
            OutboundPacketType.REFRESH_USER,
            OutboundPacketType.RESIGN,
        ];
    }

    private readonly name: string;

    constructor(name: string) {
        this.name = name;
    }

    getName(): string {
        return this.name;
    }

    static getByName(name: string): OutboundPacketType {
        for (const type of OutboundPacketType.values()) {
            if (type.getName() === name)
                return type;
        }
        throw new Error("Unknown outbound packet type: " + name);
    }
}
