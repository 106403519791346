import { z } from "zod";
import {
    APILeaderboardType,
    APIPublicGamePreferences,
    APIUser,
    createAPILeaderboardType,
} from "@/ts/business/api/api_schema";
import { fetchWithThrow } from "@/app_util/fetchWithThrow";
import { LeaderboardType } from "@/ts/business/api/leaderboard/LeaderboardType";


export const GetLeaderboardRequest = z.object({
    type: APILeaderboardType,
    from: z.number(),
    limit: z.number(),
});
export type GetLeaderboardRequest = z.infer<typeof GetLeaderboardRequest>;


export const LeaderboardEntry = z.object({
    user: APIUser,
    preferences: APIPublicGamePreferences,
    value: z.number(),
    position: z.number(),
});
export type LeaderboardEntry = z.infer<typeof LeaderboardEntry>;


export const GetLeaderboardResponse = z.object({
    type: APILeaderboardType,
    from: z.number(),
    limit: z.number(),
    entries: z.array(LeaderboardEntry),
    yourEntry: z.nullable(LeaderboardEntry),
    metadata: z.record(z.string(), z.any()),
});
export type GetLeaderboardResponse = z.infer<typeof GetLeaderboardResponse>;


export async function getLeaderboard(
    leaderboardType: LeaderboardType | null,
    from: number,
    limit: number,
): Promise<GetLeaderboardResponse | null> {
    if (leaderboardType === null)
        return null;

    const request: GetLeaderboardRequest = {
        type: createAPILeaderboardType(leaderboardType),
        from, limit,
    };
    const response = await fetchWithThrow("/api/getLeaderboard", {
        method: "POST",
        credentials: "include",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
    });
    return GetLeaderboardResponse.parse(await response.json());
}
