import { z } from "zod";
import { fetchWithThrow } from "@/app_util/fetchWithThrow";
import { APIGamePreferences } from "@/ts/business/api/api_schema";


export const GetGamePreferencesRequest = z.object({});
export type GetGamePreferencesRequest = z.infer<typeof GetGamePreferencesRequest>;


export const GetGamePreferencesResponse = z.object({
    preferences: APIGamePreferences,
});
export type GetGamePreferencesResponse = z.infer<typeof GetGamePreferencesResponse>;


export async function getGamePreferences(): Promise<APIGamePreferences> {
    const request: GetGamePreferencesRequest = {};
    const response = await fetchWithThrow("/api/getGamePreferences", {
        method: "POST",
        credentials: "include",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
    });
    const result = GetGamePreferencesResponse.parse(await response.json());
    return result.preferences;
}
