import {
    PlayerStateController,
    ReplayPlayerState,
} from "@/ts/business/game/controller/playerstate/PlayerStateController";
import { DiceController } from "@/ts/business/game/controller/dice/DiceController";
import { BoardController, ReplayMove } from "@/ts/business/game/controller/board/BoardController";
import { GameSource } from "@/ts/business/game/controller/source/GameSource";
import { Game } from "@/ts/royalur/Game";
import { ReactionController } from "@/ts/business/game/controller/reactions/ReactionController";
import { GameController } from "@/ts/business/game/controller/GameController";
import { GameState } from "@/ts/royalur/rules/state/GameState";
import { GameDirective } from "@/ts/business/game/controller/GameDirective";
import { BoardDirective } from "@/ts/business/game/controller/board/BoardDirective";
import { DiceDirective } from "@/ts/business/game/controller/dice/DiceDirective";
import { PlayerStateDirective } from "@/ts/business/game/controller/playerstate/PlayerStateDirective";
import { RolledGameState } from "@/ts/royalur/rules/state/RolledGameState";
import { RoyalUrRoll } from "@/ts/business/game/royalur/RoyalUrRoll";
import { WaitingForMoveGameState } from "@/ts/royalur/rules/state/WaitingForMoveGameState";
import { MovedGameState } from "@/ts/royalur/rules/state/MovedGameState";
import { PlayerType } from "@/ts/royalur/model/PlayerType";
import { Rune } from "@/ts/business/Rune";
import { clamp } from "@/ts/util/numbers";
import { NavigationGameEvent, NavigationType } from "@/ts/business/game/event/NavigationGameEvent";
import { MoveInfo } from "@/ts/business/analysis/MoveInfo";
import { MoveAnalysis } from "@/ts/business/analysis/MoveAnalysis";
import { GameAnalysis } from "@/ts/business/analysis/GameAnalysis";
import { MoveCategory } from "@/ts/business/analysis/MoveCategory";
import { MoveHighlight } from "@/ts/business/game/controller/board/AnalysisBoardDirective";
import { GameThemeType } from "@/ts/business/game/theme/GameThemeType";
import { ReplayMoveProperties } from "@/ts/business/game/controller/board/ReplayMovesBoardDirective";


/**
 * Coordinates the interactions within the analysis of a game.
 */
export class AnalysisGameController extends GameController {
    private game: Game;
    private moves: MoveInfo[] = [];
    private gameAnalysis: GameAnalysis | null = null;

    private readonly moveIndex: Rune<number>;
    private readonly analysedMoves: Rune<MoveAnalysis[]>;

    private pausedTargetMoveIndex?: number;

    constructor(
        playerStateController: PlayerStateController,
        reactionController: ReactionController,
        diceController: DiceController,
        boardController: BoardController,
        source: GameSource<any>,
        defaultThemeType: GameThemeType,
    ) {
        super(
            playerStateController,
            reactionController,
            diceController,
            boardController,
            source,
            defaultThemeType,
        );

        this.game = source.game.get();
        this.moveIndex = new Rune<number>(-1);
        this.analysedMoves = new Rune<MoveAnalysis[]>([]);
        this.init(this.game);

        this.postConstructor();
    }

    override setup(): () => void {
        const cleanup: (() => void)[] = [
            super.setup(),
        ];

        cleanup.push(this.source.game.subscribe(
            game => this.init(game),
        ));

        cleanup.push(this.boardController.addNavigationListener(
            e => this.processNavigation(e)
        ));
        return () => cleanup.forEach(fn => fn());
    }

    private init(game: Game) {
        this.game = game;
        this.moves = MoveInfo.listMoves(this.game);
        this.updateGameAnalysis(this.gameAnalysis, true);
        this.moveIndex.set(-1);
        this.pushDirectives(this.generateGameDirectives(-1, [], []));
    }

    getMoves(): MoveInfo[] {
        return this.moves;
    }

    getAnalysedMoves(): Rune<MoveAnalysis[]> {
        return this.analysedMoves;
    }

    getFocusedPlayer(): PlayerType | null {
        return this.getPlayers().get().getYourPlayer();
    }

    updateGameAnalysis(analysis: GameAnalysis | null, forceReset?: boolean) {
        if (!forceReset && analysis === this.gameAnalysis)
            return;

        this.gameAnalysis = analysis;

        const rawMoveAnalyses = analysis?.moveAnalyses ?? [];
        let moveAnalyses = rawMoveAnalyses;

        // End the review at the second key move when analysis is limited.
        if (analysis && analysis.hasBeenLimited && rawMoveAnalyses.length > 0) {
            const player = this.getFocusedPlayer();
            if (player) {
                moveAnalyses = [];

                let seenKeyMoves = 0;
                for (const move of rawMoveAnalyses) {
                    if (move.isKeyMove && move.player === player) {
                        moveAnalyses.push(move);
                        seenKeyMoves += 1;
                        if (seenKeyMoves >= 2)
                            break;

                    } else {
                        moveAnalyses.push(move.withoutMoveDesc());
                    }
                }
            }
        }
        this.analysedMoves.set(moveAnalyses);
    }

    getDisplayMove(): MoveInfo | null {
        return this.moves[this.moveIndex.get()] ?? null;
    }

    generateGameDirective(
        moveIndex: number,
        state: GameState,
        rollState: RolledGameState | null,
        moves: ReplayMove[],
        playerStates: ReplayPlayerState[],
        moveProperties?: ReplayMoveProperties[],
    ): GameDirective {
        const subject = state.getSubject() ?? PlayerType.LIGHT;

        const boardDirectives: BoardDirective[] = [];
        const diceDirectives: DiceDirective[] = [];
        const playerStateDirectives: PlayerStateDirective[] = [];

        // Create the dice directive.
        if (moves.length > 0) {
            diceDirectives.push(...this.diceController.createHidden());

        } else {
            let roll: RoyalUrRoll;
            if (state instanceof RolledGameState
                || state instanceof WaitingForMoveGameState
                || state instanceof MovedGameState) {

                roll = RoyalUrRoll.cast(state.getRoll());
            } else if (rollState) {
                roll = RoyalUrRoll.cast(rollState.getRoll());
            } else {
                roll = RoyalUrRoll.createZeroed(4);
            }
            diceDirectives.push(...this.diceController.createRolled(
                subject, roll,
            ));
        }

        // Create the board directive.
        if (moves.length > 0) {
            boardDirectives.push(...this.boardController.createReplayMoves(
                this.source.getRules(), moves, moveProperties,
            ));
        } else if (state instanceof MovedGameState) {
            const move = this.analysedMoves.get()[moveIndex];
            const moveHighlights: MoveHighlight[] = [];
            if (move) {
                if (move.category) {
                    moveHighlights.push({
                        move: state.getMove(),
                        category: move.category,
                    });

                    if (!move.category.isBest && move.bestMove) {
                        moveHighlights.push({
                            move: move.bestMove.move,
                            category: MoveCategory.BEST,
                        });
                    }
                }
            }

            boardDirectives.push(...this.boardController.createAnalysis(
                this.source.getRules(),
                moveIndex,
                state.getBoard(),
                state.getMove(),
                moveHighlights,
            ));
        } else {
            boardDirectives.push(...this.boardController.createWait(
                this.source.getRules(),
                moveIndex,
                state.getBoard(),
                subject,
                state.isFinished(),
            ));
        }

        // Create the player state directive.
        if (playerStates.length > 0) {
            playerStateDirectives.push(...this.playerStateController.createReplay(
                playerStates,
            ));
        } else {
            playerStateDirectives.push(...this.playerStateController.createBasic(
                moveIndex,
                state.getLightPlayer(),
                state.getDarkPlayer(),
                subject,
            ));
        }

        // Create the directive!
        return new GameDirective(
            this.assignNextDirectiveID(),
            state,
            boardDirectives,
            diceDirectives,
            playerStateDirectives,
        );
    }

    generateGameDirectives(
        moveIndex: number,
        moves: ReplayMove[],
        playerStates: ReplayPlayerState[],
        moveProperties?: ReplayMoveProperties[],
    ): GameDirective[] {

        const displayMove = this.getDisplayMove();
        if (displayMove === null) {
            const state = this.game.getStates()[0];
            return [this.generateGameDirective(
                moveIndex, state, null, moves, playerStates, moveProperties,
            )];
        }

        const { rolledState, moveState, afterState } = displayMove;
        const directives: GameDirective[] = [];
        if (moves.length > 0) {
            const state = moveState ?? afterState;
            directives.push(this.generateGameDirective(
                moveIndex, state, null, moves, playerStates, moveProperties,
            ));
        }

        if (moveState) {
            directives.push(this.generateGameDirective(moveIndex, moveState, rolledState, [], []));
        } else {
            const state = rolledState ?? afterState;
            directives.push(this.generateGameDirective(moveIndex, state, rolledState, [], []));
        }
        return directives;
    }

    updateMove(
        newMoveIndex: number,
        playMoves: boolean,
        speedSetting?: number,
        skipClearingNonLimboDirectives?: boolean,
    ) {
        const currentMoveIndex = this.moveIndex.get();

        const moves: ReplayMove[] = [];
        const playerStates: ReplayPlayerState[] = [];
        if (playMoves) {
            const firstMoveIndex = Math.max(0, currentMoveIndex);
            for (let moveIndex = firstMoveIndex; moveIndex < newMoveIndex; ++moveIndex) {
                const move = this.moves[moveIndex];
                if (move.moveState) {
                    moves.push({
                        moveIndex,
                        board: move.moveState.getBoard(),
                        move: move.moveState.getMove(),
                    });

                    // Wait until after the move to update the score.
                    const theMove = move.moveState.getMove();
                    if ((theMove.isScore() || theMove.isCapture()) && move.waitingForMoveState) {
                        playerStates.push({
                            moveIndex,
                            lightPlayerState: move.waitingForMoveState.getLightPlayer(),
                            darkPlayerState: move.waitingForMoveState.getDarkPlayer(),
                            activePlayer: move.waitingForMoveState.getTurn(),
                        });
                    } else {
                        playerStates.push({
                            moveIndex,
                            lightPlayerState: move.afterState.getLightPlayer(),
                            darkPlayerState: move.afterState.getDarkPlayer(),
                            activePlayer: move.moveState.getTurn(),
                        });
                    }
                }
            }
        }

        let moveProperties: ReplayMoveProperties[] | undefined = undefined;

        if (this.gameAnalysis && moves.length > 1) {
            moveProperties = [];

            const globalSpeed = speedSetting ?? 0.85;

            const moveLWPs = this.gameAnalysis.moveLWPs;
            for (let index = 0; index < moves.length; ++index) {
                const lastLWP = moveLWPs[index - 2];
                const lwp = moveLWPs[index - 1];
                const nextLWP = moveLWPs[index];

                let wait = 0.0;
                let speed = 1.0;
                if (lastLWP !== undefined && lwp !== undefined) {
                    const diff = Math.abs(lwp - lastLWP);
                    wait = clamp(
                        0.075 * diff - 0.2,
                        0, 0.2,
                    );
                }
                if (lwp !== undefined && nextLWP !== undefined) {
                    const diff = Math.abs(nextLWP - lwp);
                    speed = clamp(
                        1.0 + 0.075 * diff - 0.2,
                        1.0, 1.15,
                    );
                }

                moveProperties.push({
                    wait: globalSpeed * wait,
                    speed: globalSpeed * speed,
                });
            }
        }

        if (newMoveIndex !== currentMoveIndex && this.moveIndex.set(newMoveIndex, currentMoveIndex)) {

            // The arrow keys cause BoardUI to end the directives in its own way.
            if (!skipClearingNonLimboDirectives) {
                // We want to start our new directives immediately.
                this.boardController.clearNonLimboDirectives();
                this.diceController.clearNonLimboDirectives();
                this.playerStateController.clearNonLimboDirectives();
            }

            this.pushDirectives(this.generateGameDirectives(
                newMoveIndex, moves, playerStates, moveProperties,
            ));
        }
    }

    getMoveIndex(): Rune<number> {
        return this.moveIndex;
    }

    updateMoveIndex(newMoveIndex: number) {
        this.updateMove(newMoveIndex, false);
    }

    findKeyMove(startIndex: number, direction: number): number | null {
        const moves = this.analysedMoves.get();
        const player = this.getFocusedPlayer();

        let index = startIndex + direction;
        while (index >= 0 && index < moves.length) {
            const move = moves[index];
            if ((player === null || move.player === player) && move.isKeyMove)
                return index;

            index += direction;
        }
        return clamp(index, -1, moves.length - 1);
    }

    watchReplay() {
        this.updateMove(-1, false);
        this.updateMove(this.moves.length - 1, true, 1.0);
    }

    processNavigation(event: NavigationGameEvent): boolean {
        const currentMoveIndex = this.moveIndex.get();
        const eventMoveIndex = event.getMoveIndexOrNull();
        const moveIndex = (eventMoveIndex !== null ? eventMoveIndex : currentMoveIndex);

        const type = event.getType();
        if (type === NavigationType.NEXT_KEY) {
            const keyMoveIndex = this.findKeyMove(moveIndex, 1);
            if (keyMoveIndex !== null) {
                let twoMovesBackIndex = keyMoveIndex;
                let seenMoves = 0;
                while (seenMoves < 2 && twoMovesBackIndex > 0) {
                    twoMovesBackIndex -= 1;
                    const move = this.moves[twoMovesBackIndex];
                    if (move.moveState) {
                        seenMoves += 1;
                    }
                }

                // We want to animate up to 2 moves before the key move.
                if (twoMovesBackIndex > moveIndex) {
                    this.updateMove(twoMovesBackIndex, false);
                }
                this.updateMove(keyMoveIndex, true);
                return true;
            }
        }

        if (type.isForwards()) {
            const effectiveMoveIndex = (currentMoveIndex === moveIndex + 1 ? currentMoveIndex : moveIndex);
            this.updateMove(
                Math.min(effectiveMoveIndex + 1, this.moves.length - 1),
                true,
                undefined,
                true,
            );
            return true;
        } else if (type.isBackwards()) {
            this.updateMove(
                Math.max(moveIndex - 1, -1),
                false,
                undefined,
                true,
            );
            return true;
        }

        if (type === NavigationType.PAUSE) {
            this.pausedTargetMoveIndex = currentMoveIndex;
            this.updateMove(moveIndex, false);
            return true;
        } else if (type === NavigationType.PLAY) {
            this.updateMove(moveIndex, false);

            let targetMoveIndex = this.pausedTargetMoveIndex;
            if (targetMoveIndex === undefined || targetMoveIndex <= moveIndex) {
                // Play to the next key move.
                const keyMoveIndex = this.findKeyMove(moveIndex, 1);
                if (keyMoveIndex !== null) {
                    targetMoveIndex = keyMoveIndex;
                } else {
                    targetMoveIndex = this.moves.length - 1;
                }
            }
            this.updateMove(
                targetMoveIndex, true,
                (targetMoveIndex === this.moves.length - 1 ? 1.0 : undefined),
            );
            return true;
        }
        throw new Error("Unknown navigation type, " + type.getID());
    }
}
