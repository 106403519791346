import React from "react";
import { cn } from "@/ts/util/cn";

interface CloseIconProps {
    className?: string;
}

export default function CloseIcon({ className }: CloseIconProps) {
    return (
        <svg
            className={cn(
                className,
                "fill-current",
            )}
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 72 72">

            <path d="M19 15a4 4 0 0 0-2.828 6.828L30.344 36 16.172 50.172a4 4 0 1 0 5.656 5.656L36 41.656l14.172 14.172a4 4 0 1 0 5.656-5.656L41.656 36l14.172-14.172a4 4 0 1 0-5.656-5.656L36 30.344 21.828 16.172A3.99 3.99 0 0 0 19 15z" />
        </svg>
    );
}
