import { useWebSocketConnection } from "@/app_util/useWebSocketConnection";
import { startTransition, useCallback, useEffect, useState } from "react";
import { webGameAPI } from "@/app_util/WebGameAPI";
import { PacketOutJoinQueue } from "@/ts/business/game/server/outbound/PacketOutJoinQueue";
import { ClientError } from "@/ts/business/game/error/ClientError";
import { PacketInJoinLobby } from "@/ts/business/game/server/inbound/PacketInJoinLobby";
import { PacketInError } from "@/ts/business/game/server/inbound/PacketInError";
import { PacketInQueueState } from "@/ts/business/game/server/inbound/PacketInQueueState";
import { QueueState } from "@/ts/business/game/queue/QueueState";
import { areQueueTypesEqual, QueueType } from "@/ts/business/game/queue/QueueType";
import { PacketInRemovedFromQueue } from "@/ts/business/game/server/inbound/PacketInRemovedFromQueue";
import { WebSocketConnection } from "@/ts/business/game/server/WebSocketConnection";


export function useMatchmaking(joinLobby: (lobbyID: string) => void): {
    activeQueue: QueueState | null;
    requestedQueue: QueueType | null;
    setRequestedQueue: (queue: QueueType | null) => void;
    connected: boolean;
    connection: WebSocketConnection | null;
    error: ClientError | null;
} {
    const [activeQueue, setActiveQueue] = useState<QueueState | null>(null);
    const [requestedQueue, setRequestedQueue] = useState<QueueType | null>(null);
    const [error, setError] = useState<ClientError | null>(null);

    const { connected, connection } = useWebSocketConnection(
        requestedQueue !== null ? webGameAPI.getMatchmakingWebSocketURL() : null,
    );

    useEffect(() => connection?.addPacketListener((packet) => {
        if (packet instanceof PacketInJoinLobby) {
            joinLobby(packet.getLobbyID());

        } else if (packet instanceof PacketInError) {
            setError(packet.getError());

        } else if (packet instanceof PacketInQueueState) {
            startTransition(() => {
                setActiveQueue(packet.getQueueState());
                setError(null);
            });

        } else if (packet instanceof PacketInRemovedFromQueue) {
            setActiveQueue((currentQueue) => {
                if (currentQueue === null)
                    return currentQueue;
                if (!areQueueTypesEqual(packet.getQueueType(), currentQueue.queueType))
                    return currentQueue;

                // Queue type matches one from packet - mark that we're no longer queued.
                return null;
            });
        }
    }), [setActiveQueue, joinLobby, connection]);

    const sendJoinQueuePacket = useCallback((queueToJoin: QueueType) => {
        connection?.trySend(new PacketOutJoinQueue(
            queueToJoin.gameMode, queueToJoin.gameType,
        ));
    }, [connection]);

    // Send packet to join queue after connecting.
    useEffect(() => connection?.addOpenListener(() => {
        setError(null);
        if (requestedQueue) {
            sendJoinQueuePacket(requestedQueue);
        }
    }), [setError, requestedQueue, connection, sendJoinQueuePacket]);

    // Send packet to join queue after user changes their queue selection.
    useEffect(() => {
        if (requestedQueue) {
            sendJoinQueuePacket(requestedQueue);
        } else {
            setActiveQueue(null);
        }
    }, [setActiveQueue, requestedQueue, sendJoinQueuePacket]);

    return {
        activeQueue,
        requestedQueue,
        setRequestedQueue,
        connected,
        connection,
        error,
    };
}
