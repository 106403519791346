import { useEffect, useState } from "react";
import styles from "./DotDotDot.module.scss";

interface DotDotDotProps {
    enabled: boolean;
}

export function DotDotDot({ enabled = true }: DotDotDotProps) {
    const [dots, setDots] = useState<string>("...");

    useEffect(() => {
        if (!enabled)
            return;

        const state = {
            dots: "...",
        };
        setDots(state.dots);

        const intervalID = setInterval(() => {
            if (state.dots === "...") {
                state.dots = ".";
            } else if (state.dots === ".") {
                state.dots = "..";
            } else {
                state.dots = "...";
            }
            setDots(state.dots);
        }, 1000);

        return () => {
            clearInterval(intervalID);
        };
    }, [enabled]);

    if (!enabled)
        return (<></>);

    return (
        <span className={styles.dots_container}>
            <span className={styles.dots_spacer}>
                ...
            </span>
            <span className={styles.dots}>
                {dots}
            </span>
        </span>
    );
}
