import { z } from "zod";
import { ErrorType } from "@/ts/business/game/error/ErrorType";


export const ExceededPandaLimitData = z.object({
    available_again_at: z.coerce.date(),
    ongoing_lobby_id: z.nullable(z.string()),
});
export type ExceededPandaLimitData = z.infer<typeof ExceededPandaLimitData>;


export class ClientError extends Error {
    private readonly type: ErrorType;
    private readonly data?: Record<string, any>;

    constructor(type: ErrorType, message: string, data?: Record<string, any>) {
        super(message);
        this.type = type;
        this.data = data;
    }

    getErrorType(): ErrorType {
        return this.type;
    }

    getData(): Record<string, any> | undefined {
        return this.data;
    }

    getExceededPandaLimitData(): ExceededPandaLimitData | null {
        if (this.data === undefined || this.type !== ErrorType.EXCEEDED_PANDA_LIMIT)
            return null;

        const parsedData = ExceededPandaLimitData.safeParse(this.data);
        return (parsedData.success ? parsedData.data : null);
    }
}
