import { WebSocketConnection } from "@/ts/business/game/server/WebSocketConnection";
import { useEffect, useMemo } from "react";
import { useOptionalRune } from "@/app_util/useRune";


export function useWebSocketConnection(
    url: string | null,
): {
    connected: boolean;
    connection: WebSocketConnection | null;
} {

    const connection = useMemo(() => {
        if (url === null)
            return null;

        return new WebSocketConnection(url);
    }, [url]);

    // Connect.
    useEffect(() => connection?.setup(), [connection]);

    // Read connection status.
    const connected = useOptionalRune(connection?.getConnected()) ?? false;

    return { connected, connection };
}
