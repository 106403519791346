import { QueueType, readJsonQueueType } from "@/ts/business/game/queue/QueueType";


export interface QueueState {
    queueType: QueueType;
}


export function readJsonQueueState(json: Record<string, any>): QueueState {
    const queueType = readJsonQueueType(json);
    return { queueType };
}
