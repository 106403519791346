import { GameSource } from "@/ts/business/game/controller/source/GameSource";
import { MoveGameEvent } from "@/ts/business/game/event/MoveGameEvent";
import { RollGameEvent } from "@/ts/business/game/event/RollGameEvent";
import { PlayerType } from "@/ts/royalur/model/PlayerType";
import { Agent } from "@/ts/royalur/agent/Agent";
import { Game } from "@/ts/royalur/Game";
import { getTimeSeconds, getTimeMs } from "@/ts/util/utils";
import { GameMode } from "@/ts/business/game/GameMode";
import { GameUpdateEvent } from "@/ts/business/game/controller/source/GameUpdateEvent";
import { GamePlayers } from "@/ts/business/api/game/GamePlayers";
import { GamePlayerData } from "@/ts/business/api/game/GamePlayerData";
import { BotType } from "@/ts/business/game/BotType";
import { AnalyticsProvider } from "@/ts/business/analytics/Analytics";
import { LobbySettings } from "@/ts/business/game/LobbySettings";
import { GameDiff } from "@/ts/royalur/GameDiff";
import { MovedGameState } from "@/ts/royalur/rules/state/MovedGameState";
import { RolledGameState } from "@/ts/royalur/rules/state/RolledGameState";
import { MessageOutUploadGame } from "@/ts/business/api/game/MessageOutUploadGame";
import { HumanLobbyPlayer } from "@/ts/business/lobby/HumanLobbyPlayer";
import { BotLobbyPlayer } from "@/ts/business/lobby/BotLobbyPlayer";
import { RuleSet } from "@/ts/royalur/rules/RuleSet";
import { GameAPI } from "@/ts/business/api/GameAPI";
import { APIGamePreferences, APIUser } from "@/ts/business/api/api_schema";
import { GameClientControls } from "@/ts/business/GameClientControls";
import { ReactionType } from "@/ts/business/game/ReactionType";
import { GameEndReason } from "@/ts/business/game/GameEndReason";
import { LocalLobbyStatus } from "@/ts/business/game/controller/status/LocalLobbyStatus";
import { LobbyID } from "@/ts/business/lobby/LobbyID";


export class ComputerGameSource extends GameSource<LocalLobbyStatus> {
    public static readonly CHECK_INTERVAL_MS = 1000;

    private readonly user: APIUser | null;
    private readonly botType: BotType;
    private readonly botAgent: Agent;

    private currentAgentPromise: Promise<Game> | null = null;
    private lastMonitorMs: number;

    constructor(
        initialClientControls: GameClientControls,
        initialPreferences: APIGamePreferences,
        user: APIUser | null,
        analytics: AnalyticsProvider,
        gameAPI: GameAPI,
        setupSettings: LobbySettings,
        rules: RuleSet,
        botType: BotType,
        botAgent: Agent,
    ) {
        super(
            initialClientControls, initialPreferences, analytics, gameAPI, setupSettings,
            new LocalLobbyStatus(
                LobbyID.generateRandom(),
                GameMode.COMPUTER, botType, null, null, null,
            ),
            rules,
        );
        if (botType.isOnline())
            throw new Error("Bot type of " + botType.getName() + " requires an online game source");

        this.user = user;
        this.botType = botType;
        this.botAgent = botAgent;

        this.lastMonitorMs = getTimeMs();
        this.handleGameSetup(null);

        this.game.subscribe((game) => {
            if (game.isFinished()) {
                analytics.recordFinishGame(setupSettings);
            }
        });
    }

    override getControllerMaxDirectives(): number {
        // A value of 0 represents no limit.
        return 0;
    }

    getHumanPlayer(): PlayerType {
        return this.players.get().getLeftPlayer();
    }

    getBotPlayer(): PlayerType {
        return this.getHumanPlayer().getOtherPlayer();
    }

    override isLocalHumanPlayer(playerType: PlayerType): boolean {
        return playerType === this.getHumanPlayer();
    }

    private static decideHumanPlayer(lastHumanPlayer: PlayerType | null): PlayerType {
        // Make it more likely that the player type will swap back and forth.
        const threshold = (
            lastHumanPlayer === null ? 0.5
                : (lastHumanPlayer === PlayerType.LIGHT ? 0.75 : 0.25)
        );
        return Math.random() < threshold ? PlayerType.DARK : PlayerType.LIGHT;
    }

    private handleGameSetup(lastHumanPlayer: PlayerType | null): void {
        const game = this.getRules().generateGame();
        const leftPlayer = ComputerGameSource.decideHumanPlayer(lastHumanPlayer);

        const humanData = new GamePlayerData(1, leftPlayer);
        const botData = new GamePlayerData(2, leftPlayer.getOtherPlayer());

        const human = new HumanLobbyPlayer(this.user, humanData, this.preferences.get());
        const bot = new BotLobbyPlayer(this.botType, botData);

        this.updateGameAndPlayers(
            new GamePlayers(
                leftPlayer, leftPlayer,
                leftPlayer === PlayerType.LIGHT ? human : bot,
                leftPlayer === PlayerType.DARK ? human : bot,
            ),
            game, new GameUpdateEvent(true),
        );
        this.lobbyStatus.map(status => status.withCompleteGameID(null));
        this.getAnalytics().recordStartGame(this.getSetupSettings());
    }

    override setup(): () => void {
        const interval = setInterval(() => {
            this.monitor();
        }, 250);

        return () => {
            clearInterval(interval);
        };
    }

    monitor() {
        const timeSinceMonitorMs = getTimeMs() - this.lastMonitorMs;
        if (timeSinceMonitorMs < ComputerGameSource.CHECK_INTERVAL_MS)
            return;

        this.maybePlayAgentTurn();
        this.lastMonitorMs = getTimeMs();
    }

    override handleResign(): void {
        const newGame = this.game.get().copy();
        const player = this.getHumanPlayer();
        newGame.resign(player);

        // We don't want to send the game update event
        // until we've updated the lobby status as well.
        const sendGameEvent = this.game.setAndDeferEvent(
            newGame, new GameUpdateEvent(false),
        );

        this.lobbyStatus.map(status => status.withEnd(GameEndReason.RESIGNATION, player));
        sendGameEvent();
    }

    override handleMove(event: MoveGameEvent): void {
        const game = this.game.get();
        if (game.isWaitingForMove() && game.getTurn() === this.getHumanPlayer()) {
            game.move(event.getMove());
            this.game.set(game, new GameUpdateEvent(event.getSkipAnimation()));
            this.maybePlayAgentTurn();
        }
    }

    override handleRoll(_event: RollGameEvent): void {
        const game = this.game.get();
        if (game.isWaitingForRoll() && game.getTurn() === this.getHumanPlayer()) {
            game.rollDice();
            this.game.set(game, new GameUpdateEvent(false));
            this.maybePlayAgentTurn();
        }
    }

    override handleReaction(player: PlayerType | null, reaction: ReactionType) {
        this.triggerReaction(player, reaction);
    }

    private maybePlayAgentTurn() {
        const game = this.game.get();
        if (game.isFinished() || game.getTurn() !== this.getBotPlayer())
            return;
        if (this.currentAgentPromise !== null)
            return;

        const startTime = getTimeSeconds();
        this.currentAgentPromise = this.botAgent.playTurn(game);

        this.currentAgentPromise.then((newGame: Game) => {
            this.currentAgentPromise = null;

            if (newGame === game) {
                this.game.set(newGame, new GameUpdateEvent(false));
                return;
            }

            const duration = getTimeSeconds() - startTime;
            if (duration > 0.1) {
                console.log(`AI took ${duration.toFixed(3)} seconds`);
            }

            const diff = GameDiff.create(newGame, game, true);
            const actions = diff.getAddedActionStates();

            // There is a long-standing bug where the AI will make moves for
            // itself AND the human player. It happens rarely, so it is hard
            // to track down. This is an attempt to do that, or at least stop
            // it from happening.
            if (actions.length !== 1) {
                console.error("New game does not have exactly one added action!", actions);
                if (actions.length > 1) {
                    newGame = game.copy();
                    const action = actions[0];
                    if (action instanceof MovedGameState) {
                        newGame.move(action.getMove());
                    } else if (action instanceof RolledGameState) {
                        newGame.rollDice(action.getRoll());
                    } else {
                        throw new Error("Unknown action!");
                    }
                }
            }

            const updated = this.game.set(newGame, new GameUpdateEvent(false), game);
            if (updated) {
                this.maybePlayAgentTurn();
            }
        });
    }

    override shouldUploadGame(): boolean {
        // If the game has already been uploaded, it will have an ID.
        return this.lobbyStatus.get().getCompleteGameID() === null;
    }

    override buildUploadGameMessage(): MessageOutUploadGame {
        const game = this.game.get();
        if (!game.isFinished())
            throw new Error("The game is not finished");

        const players = this.players.get();
        const status = this.lobbyStatus.get();
        return new MessageOutUploadGame(
            status.getLobbyID().get(),
            status.getGameMode(),
            players.getPlayer1(),
            players.getPlayer2(),
            game,
        );
    }
}
