import { GameType } from "@/ts/business/game/GameType";
import { PopupButton } from "@/app_components/generic/popup/PopupButton";
import styles from "./RuleSetPopupButton.module.scss";
import Image from "next/image";
import { getGameTypeIcon } from "@/app_components/icon/getGameTypeIcon";
import React from "react";


interface RuleSetPopupButtonProps {
    gameType: GameType;
}


export function RuleSetPopupButton({ gameType }: RuleSetPopupButtonProps) {
    return (
        <PopupButton
            buttonClassName={styles.ruleset}
            buttonContent={(
                <>
                    <Image src={getGameTypeIcon(gameType)} alt={gameType.getDesc()} />
                    <p>
                        {gameType.getName()}
                    </p>
                </>
            )}>

            <span className="font-bold">
                {`${gameType.getName()} Rules: `}
            </span>
            <span>
                {gameType.getDesc()}
            </span>
        </PopupButton>
    );
}
