"use client";

import styles from "./CookiePreferencesButton.module.scss";
import { cn } from "@/ts/util/cn";


export interface CookiePreferencesButtonProps {
    className: string | undefined;
}


export function CookiePreferencesButton({ className }: CookiePreferencesButtonProps) {
    return (
        <a
            className={cn(
                styles.button,
                className
            )}
            onClick={() => {
                if (window.__tcfapi) {
                    window.__tcfapi("displayConsentUi", 2, () => {});
                }
            }}>

            Privacy Settings
        </a>
    );
}
