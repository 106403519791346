import { PacketOut } from "@/ts/business/game/server/outbound/PacketOut";
import { PacketOutPing } from "@/ts/business/game/server/outbound/PacketOutPing";
import { MessageIOContext } from "@/ts/business/api/MessageIOContext";
import { PacketIn } from "@/ts/business/game/server/inbound/PacketIn";
import { getJsonType, isJsonDict, readJsonString } from "@/ts/util/json";
import { InboundPacketType } from "@/ts/business/game/server/inbound/InboundPacketType";
import { buildPacketIn } from "@/ts/business/game/server/inbound/buildPacketIn";
import { PacketInSetID } from "@/ts/business/game/server/inbound/PacketInSetID";
import { PacketOutReOpen } from "@/ts/business/game/server/outbound/PacketOutReOpen";
import { PacketOutOpen } from "@/ts/business/game/server/outbound/PacketOutOpen";
import { PacketInPong } from "@/ts/business/game/server/inbound/PacketInPong";
import { ListenerStore } from "@/ts/business/ListenerStore";
import { GameProtocolMetadata } from "@/ts/business/game/server/GameProtocolMetadata";
import { PacketInError } from "@/ts/business/game/server/inbound/PacketInError";
import { readSessionStorage, readStorage, writeStorage } from "@/ts/util/storage";
import { Rune } from "@/ts/business/Rune";


export type PacketListener = (packet: PacketIn) => void;
export type OpenListener = () => void;
export type CloseListener = () => void;


/**
 * Represents a connection to the game server.
 */
export class WebSocketConnection {
    public static readonly SESSION_ID_KEY = "connection_session_id";

    public static readonly MONITOR_INTERVAL_MS = 3000;
    public static readonly DISCONNECT_TIME_MS = 6000;

    private readonly url: string;
    private readonly context: MessageIOContext;

    private readonly packetListeners: ListenerStore<PacketListener>;
    private readonly openListeners: ListenerStore<OpenListener>;
    private readonly closeListeners: ListenerStore<CloseListener>;
    private readonly connected: Rune<boolean>;

    private socket: WebSocket | null = null;
    private disabled: boolean = false;
    private id: string | null = null;

    private lastPingSendTimeMs: number | null = null;
    private pingMs: number | null = null;
    private serverTimeCorrectionMs: number = 0;

    constructor(url: string) {
        this.url = url;
        this.context = MessageIOContext.createDefault();
        this.packetListeners = new ListenerStore<PacketListener>();
        this.openListeners = new ListenerStore<OpenListener>();
        this.closeListeners = new ListenerStore<CloseListener>();
        this.connected = new Rune<boolean>(false);
    }

    getConnected(): Rune<boolean> {
        return this.connected;
    }

    getPingMs(): number | null {
        return this.pingMs;
    }

    /**
     * Adjusts the server time to synchronise it with the client time.
     */
    correctServerTime(serverTimeMs: number): number {
        return serverTimeMs + this.serverTimeCorrectionMs;
    }

    static restoreID(): string | null {
        // We want to prefer session storage.
        const sessionValue = readSessionStorage(WebSocketConnection.SESSION_ID_KEY);
        if (sessionValue !== null)
            return sessionValue;

        return readStorage(WebSocketConnection.SESSION_ID_KEY);
    }

    static saveID(id: string) {
        return writeStorage(WebSocketConnection.SESSION_ID_KEY, id);
    }

    /**
     * The actual URL that is being loaded.
     */
    getURL(): string {
        return this.url;
    }

    addPacketListener(listener: PacketListener): () => void {
        return this.packetListeners.add(listener);
    }

    addOpenListener(listener: OpenListener): () => void {
        const removeListener = this.openListeners.add(listener);
        // Call the listener now if the connection is already open.
        if (this.socket?.readyState === 1) {
            listener();
        }
        return removeListener;
    }

    addCloseListener(listener: CloseListener): () => void {
        return this.closeListeners.add(listener);
    }

    setup(): () => void {
        this.connect();
        const monitorInterval = setInterval(() => this.monitor(), WebSocketConnection.MONITOR_INTERVAL_MS);

        const closeTabListener = () => {
            if (this.id) {
                WebSocketConnection.saveID(this.id);
            }
        };
        window.addEventListener("beforeunload", closeTabListener);

        return () => {
            clearInterval(monitorInterval);
            window.removeEventListener("beforeunload", closeTabListener);
            this.disconnect();
        };
    }

    disconnect() {
        if (this.socket === null)
            return;

        const readyState = this.socket.readyState;
        if (readyState !== WebSocket.CLOSING && readyState !== WebSocket.CLOSED) {
            this.socket.close();
        }
        this.socket = null;
        this.connected.set(false);
    }

    connect() {
        // Reset.
        this.lastPingSendTimeMs = null;
        this.pingMs = null;
        this.connected.set(false);

        // Connect!
        const socket = new WebSocket(this.url);
        socket.onopen = () => this.onOpen();
        socket.onclose = () => this.onClose();
        socket.onmessage = (event: MessageEvent) => this.onMessage(event.data);
        this.socket = socket;
    }

    /**
     * Disconnects the socket, and stops this connection
     * from trying to reconnect.
     */
    disable() {
        this.disabled = true;
        this.disconnect();
    }

    monitor() {
        const clientTimeMs = Date.now();

        if (this.disabled) {
            this.disconnect();
            this.connected.set(false);
            return;
        }

        // Check if we need to connect.
        if (this.socket === null) {
            this.connect();
            return;
        } else {
            const readyState = this.socket.readyState;
            if (readyState === WebSocket.CLOSING || readyState === WebSocket.CLOSING) {
                this.connect();
                return;
            }
        }

        // If we haven't received a response to our last ping,
        // our connection is bad!
        if (this.lastPingSendTimeMs !== null) {
            const timeSincePing = clientTimeMs - this.lastPingSendTimeMs;
            if (timeSincePing > WebSocketConnection.DISCONNECT_TIME_MS) {
                this.disconnect();
                this.connect();
            }
            return;
        }

        // Ping the server.
        this.lastPingSendTimeMs = clientTimeMs;
        this.trySend(new PacketOutPing(clientTimeMs));
    }

    trySend(packet: PacketOut): boolean {
        if (!this.socket || this.socket.readyState !== WebSocket.OPEN)
            return false;

        const message = JSON.stringify(packet.write(this.context));
        console.log("send", message);
        this.socket.send(message);
        return true;
    }

    send(packet: PacketOut) {
        if (!this.trySend(packet))
            throw new Error("Socket is not connected: " + (this.socket?.readyState ?? null));
    }

    onOpen() {
        // This can get called while still connecting on
        // some mobile phones for whatever reason...
        if (this.socket?.readyState !== 1)
            return;

        const id = this.id ?? WebSocketConnection.restoreID();
        if (id !== null) {
            this.send(new PacketOutReOpen(
                GameProtocolMetadata.version,
                id,
            ));
        } else {
            this.send(new PacketOutOpen(
                GameProtocolMetadata.version,
            ));
        }
    }

    onClose() {
        this.closeListeners.invoke();
        this.socket = null;
    }

    onMessage(message: string): void {
        console.log("receive", message);
        const json = JSON.parse(message);
        if (!isJsonDict(json))
            throw new Error("Expected a JSON object, not a " + getJsonType(json));

        const typeName = readJsonString(json, PacketIn.PACKET_TYPE_KEY);
        const type = InboundPacketType.getByName(typeName);
        const packet = buildPacketIn(type);
        packet.read(this.context, json);

        if (packet instanceof PacketInPong) {
            this.handlePong(packet);

        } else if (packet instanceof PacketInSetID) {
            this.handleSetID(packet);

        } else if (this.id !== null || packet instanceof PacketInError) {
            this.packetListeners.invoke(packet);

        } else {
            throw new Error("Unexpected packet: " + typeName);
        }
    }

    handlePong(packet: PacketInPong) {
        if (packet.clientTimeMs === null || packet.serverTimeMs === null)
            throw new Error("Missing clientTimeMs or serverTimeMs");

        const currentClientTimeMs = Date.now();
        this.pingMs = currentClientTimeMs - packet.clientTimeMs;
        const estimatedServerTimeMs = currentClientTimeMs - this.pingMs / 2;
        this.serverTimeCorrectionMs = estimatedServerTimeMs - packet.serverTimeMs;
        this.lastPingSendTimeMs = null;
    }

    handleSetID(packet: PacketInSetID) {
        if (packet.id === null)
            throw new Error("Missing id!");

        this.id = packet.id;
        WebSocketConnection.saveID(packet.id);
        this.openListeners.invoke();
        this.connected.set(true);
    }
}
