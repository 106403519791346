import { GameSource } from "@/ts/business/game/controller/source/GameSource";
import { LobbyStatus } from "@/ts/business/game/controller/status/LobbyStatus";
import { z } from "zod";
import { LobbySummary } from "@/ts/business/lobby/LobbySummary";
import { Bot } from "@/ts/business/game/Bot";
import { GameAnalysis } from "@/ts/business/analysis/GameAnalysis";
import { RuleSet } from "@/ts/royalur/rules/RuleSet";
import { Optional } from "@/ts/util/Optional";
import { Game } from "@/ts/royalur/Game";
import { GameMode } from "@/ts/business/game/GameMode";
import { GameSettings } from "@/ts/royalur/model/GameSettings";
import { BotType } from "@/ts/business/game/BotType";
import { GameType } from "@/ts/business/game/GameType";


export const CreateGameResponse = z.object({
    lobby_id: z.string(),
});
export type CreateGameResponse = z.infer<typeof CreateGameResponse>;


export const AvailableBot = z.object({
    type: z.string(),
    settings: z.string(),
});
export type AvailableBot = z.infer<typeof AvailableBot>;


export const WaitingPlayerStateResponse = z.object({
    mode: z.string(),
    settings: z.string(),
    waiting_count: z.number(),
});
export type WaitingPlayerStateResponse = z.infer<typeof WaitingPlayerStateResponse>;


export const ListLiveLobbiesResponse = z.object({
    available_bots: z.array(AvailableBot),
    waiting_players: z.array(WaitingPlayerStateResponse),
    started_lobbies_is_exhaustive: z.boolean(),
    started_lobbies: z.array(z.record(z.string(), z.any())),
});
export type ListLiveLobbiesResponse = z.infer<typeof ListLiveLobbiesResponse>;


export interface WaitingPlayerState {
    mode: GameMode;
    settings: GameType;
    waitingCount: number;
}


export interface LobbyManagerState {
    availableBots: Bot[];
    waitingPlayers: WaitingPlayerState[];
    startedLobbies: LobbySummary[];
    startedLobbiesIsExhaustive: boolean;
}


export abstract class GameAPI {

    abstract createGame(
        gameMode: GameMode,
        settings: GameSettings,
        botType: BotType | null
    ): Promise<CreateGameResponse>;

    abstract uploadGame(source: GameSource<LobbyStatus>): Promise<void>;

    abstract analyseGame(rules: RuleSet, gameID: string): Promise<Optional<GameAnalysis>>;

    abstract getPandaGame(): Promise<Game>;

    abstract getLobbyManagerState(): Promise<LobbyManagerState>;

    abstract getLobbyWebSocketURL(lobbyID: string): string;

    abstract getMatchmakingWebSocketURL(): string;
}
