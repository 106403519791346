import { z } from "zod";
import { ClientError } from "@/ts/business/game/error/ClientError";
import { ErrorType } from "@/ts/business/game/error/ErrorType";


const ClientErrorResponse = z.object({
    error_type: z.string(),
    message: z.string(),
    data: z.optional(z.record(z.string(), z.any())),
});


export async function fetchWithThrow(
    input: string | URL | globalThis.Request,
    init?: RequestInit,
): Promise<Response> {
    const response = await fetch(input, init);
    if (response.ok)
        return response;

    let rawText: string | null = null;
    try {
        rawText = await response.text();
    } catch (_) { /* ignored */ }

    let errorMessage = `Error ${response.status}: ${response.statusText}`;
    let clientError: ClientError | null = null;
    if (rawText) {
        try {
            // Nice errors will give us some structured information.
            const json = JSON.parse(rawText);
            const parsedError = ClientErrorResponse.parse(json);

            const errorType = ErrorType.getByIDOrUnknown(parsedError.error_type);
            errorMessage = parsedError.message;
            clientError = new ClientError(
                errorType, errorMessage, parsedError.data,
            );
        } catch (_) {
            // JSON parsing failed; fallback to raw text.
            errorMessage = `Error ${response.status}: ${rawText}`;
        }
    }
    throw clientError ?? new Error(errorMessage);
}
