import { GameType } from "@/ts/business/game/GameType";


export class LeaderboardGrouping {
    public static readonly OVERALL = new LeaderboardGrouping("overall", "Overall", null);
    public static readonly FINKEL = new LeaderboardGrouping("finkel", "Finkel", GameType.FINKEL);
    public static readonly BLITZ = new LeaderboardGrouping("blitz", "Blitz", GameType.BLITZ);
    public static readonly MASTERS = new LeaderboardGrouping("masters", "Masters", GameType.MASTERS);

    static values(): LeaderboardGrouping[] {
        return [
            LeaderboardGrouping.OVERALL,
            LeaderboardGrouping.FINKEL,
            LeaderboardGrouping.BLITZ,
            LeaderboardGrouping.MASTERS,
        ];
    }

    private readonly id: string;
    private readonly name: string;
    private readonly gameType: GameType | null;

    constructor(id: string, name: string, gameType: GameType | null) {
        this.id = id;
        this.name = name;
        this.gameType = gameType;
    }

    getID(): string {
        return this.id;
    }

    getName(): string {
        return this.name;
    }

    getGameTypeOrNull(): GameType | null {
        return this.gameType;
    }

    static getByID(id: string): LeaderboardGrouping {
        for (const leaderboardType of LeaderboardGrouping.values()) {
            if (leaderboardType.id === id)
                return leaderboardType;
        }
        throw new Error("Unknown game mode " + id);
    }

    static getByIDOrNull(id: string | null): LeaderboardGrouping | null {
        if (id === null)
            return null;

        for (const leaderboardType of LeaderboardGrouping.values()) {
            if (leaderboardType.id === id)
                return leaderboardType;
        }
        return null;
    }
}
