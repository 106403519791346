import { PacketIn } from "@/ts/business/game/server/inbound/PacketIn";
import { InboundPacketType } from "@/ts/business/game/server/inbound/InboundPacketType";
import { readJsonString, readNullableJsonDict } from "@/ts/util/json";
import { MessageIOContext } from "@/ts/business/api/MessageIOContext";
import { ErrorType } from "@/ts/business/game/error/ErrorType";
import { ClientError } from "@/ts/business/game/error/ClientError";


export class PacketInError extends PacketIn {
    public error: ClientError | null = null;

    constructor() {
        super(InboundPacketType.ERROR);
    }

    getError(): ClientError {
        if (this.error === null)
            throw new Error("Packet has not been read yet");
        return this.error;
    }

    override readContents(
        _context: MessageIOContext,
        json: Record<string, any>,
    ): void {
        const type = ErrorType.getByIDOrUnknown(readJsonString(json, "error_type"));
        const message = readJsonString(json, "message");
        const data = readNullableJsonDict(json, "data");
        this.error = new ClientError(type, message, (data !== null ? data : undefined));
    }
}
