import { PacketOut } from "@/ts/business/game/server/outbound/PacketOut";
import { OutboundPacketType } from "@/ts/business/game/server/outbound/OutboundPacketType";
import { MessageIOContext } from "@/ts/business/api/MessageIOContext";
import { GameMode } from "@/ts/business/game/GameMode";
import { GameType } from "@/ts/business/game/GameType";


export class PacketOutJoinQueue extends PacketOut {
    private readonly queueMode: GameMode;
    private readonly queueType: GameType;

    constructor(queueMode: GameMode, queueType: GameType) {
        super(OutboundPacketType.JOIN_QUEUE);
        this.queueMode = queueMode;
        this.queueType = queueType;
    }

    override writeContents(_context: MessageIOContext): Record<string, any> {
        return {
            queue_game_mode: this.queueMode.getID(),
            queue_game_type: this.queueType.getID(),
        };
    }
}
