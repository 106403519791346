import styles from "./MatchmakingControls.module.scss";
import { useLobbyManagerState } from "@/app_util/api/useLobbyManagerState";
import React, { startTransition, useCallback, useEffect, useMemo, useState } from "react";
import { LobbySummary } from "@/ts/business/lobby/LobbySummary";
import { motion, AnimatePresence } from "framer-motion";
import { fadeInOut } from "@/app_util/fadeInOut";
import { cn } from "@/ts/util/cn";
import { GameType } from "@/ts/business/game/GameType";
import RoyalUrButton from "@/app_components/generic/buttons/RoyalUrButton";
import { LobbyPlayerDisplayName } from "@/app_components/user/LobbyPlayerDisplayName";
import { Path } from "@/ts/util/Path";
import { GameClientControls } from "@/ts/business/GameClientControls";
import { GameMode } from "@/ts/business/game/GameMode";
import Link from "next/link";
import { LoginPrompt } from "@/app_components/prompt/LoginPrompt";
import { useOptionalUser } from "@/app_components/user/UserContext";
import { useMatchmaking } from "@/app_util/game/useMatchmaking";
import { RemoteLobbySettings } from "@/ts/business/game/RemoteLobbySettings";
import { RuleSetPopupButton } from "@/app_components/gamesetup/RuleSetPopupButton";
import { ClientError } from "@/ts/business/game/error/ClientError";
import { GameTypeCounts } from "@/ts/business/lobby/GameTypeCounts";
import { DotDotDot } from "@/app_components/game/timing/DotDotDot";
import CloseIcon from "@/app_components/icon/CloseIcon";
import { FadingDiv } from "@/app_components/layout/FadingDiv";
import { QueueState } from "@/ts/business/game/queue/QueueState";
import { areQueueTypesEqual, QueueType } from "@/ts/business/game/queue/QueueType";
import { WaitingPlayerState } from "@/ts/business/api/GameAPI";


export interface WaitingPlayerCounts {
    ranked: number;
    casual: number;
}


export class MatchmakingState {
    public readonly activeQueue: QueueState | null;
    public readonly requestedQueue: QueueType | null;
    public readonly setRequestedQueue: (queue: QueueType | null) => void;
    public readonly connected: boolean;
    public readonly error: ClientError | null;
    public readonly waitingPlayers: WaitingPlayerState[];
    public readonly activeLobbies: LobbySummary[];
    public readonly activeLobbyCounts: GameTypeCounts;

    constructor(
        activeQueue: QueueState | null,
        requestedQueue: QueueType | null,
        setRequestedQueue: (queue: QueueType | null) => void,
        connected: boolean,
        error: ClientError | null,
        waitingPlayers: WaitingPlayerState[],
        activeLobbies: LobbySummary[],
    ) {
        this.activeQueue = activeQueue;
        this.requestedQueue = requestedQueue;
        this.setRequestedQueue = setRequestedQueue;
        this.connected = connected;
        this.error = error;
        this.waitingPlayers = waitingPlayers;
        this.activeLobbies = activeLobbies;
        this.activeLobbyCounts = GameTypeCounts.countPlayersBySettings(activeLobbies);
    }

    public getWaitingPlayerCounts(settings: GameType): WaitingPlayerCounts {
        let ranked = 0;
        let casual = 0;
        for (const state of this.waitingPlayers) {
            if (state.settings === settings) {
                if (state.mode === GameMode.RANKED) {
                    ranked = state.waitingCount;
                } else {
                    casual = state.waitingCount;
                }
            }
        }
        return { ranked, casual };
    }

    /**
     * A band-aid to help with stopping seeing yourself as a waiting player.
     */
    public minusOneWaiting(mode: GameMode, settings: GameType) {
        for (const state of this.waitingPlayers) {
            if (state.mode === mode && state.settings === settings) {
                state.waitingCount = Math.max(0, state.waitingCount - 1);
            }
        }
    }
}


interface LiveLobbiesListEntryProps {
    summary: LobbySummary;
    getWatchLink: (lobbyID: string) => Path;
}


function LiveLobbiesEntry({ summary, getWatchLink }: LiveLobbiesListEntryProps) {
    return (
        <div className={styles.entry}>
            <div className={styles.ruleset}>
                <RuleSetPopupButton gameType={summary.settings} />
            </div>

            <div className={styles.details}>
                <p>
                    <LobbyPlayerDisplayName player={summary.player1} />
                    {" vs. "}
                    <LobbyPlayerDisplayName player={summary.player2} />

                    {summary.mode === GameMode.ONLINE && " (Casual)"}
                    {summary.mode === GameMode.RANKED && " (Ranked)"}
                </p>
            </div>

            <div className={styles.watch_button}>
                <RoyalUrButton
                    size="tiny"
                    style="cta"
                    href={getWatchLink(summary.lobbyID).toString()}>

                    Watch
                </RoyalUrButton>
            </div>
        </div>
    );
}


interface LiveLobbiesEntryListProps {
    summaries: LobbySummary[];
    getWatchLink: (lobbyID: string) => Path;
}


function LiveLobbiesEntryList({ summaries, getWatchLink }: LiveLobbiesEntryListProps) {
    const elems = [];
    for (const summary of summaries) {
        elems.push(
            <LiveLobbiesEntry
                key={summary.lobbyID}
                summary={summary}
                getWatchLink={getWatchLink} />,
        );
    }
    return (
        <>
            {elems}
        </>
    );
}


interface PendingLobbyListEntryProps {
    gameType: GameType | null;
    waitingRankedCount: number;
    waitingCasualCount: number;
    playingCount: number;
    matchmakingState: MatchmakingState;
}


function PendingLobbyListEntry({
    gameType, waitingRankedCount, waitingCasualCount, playingCount, matchmakingState,
}: PendingLobbyListEntryProps) {

    const user = useOptionalUser();

    const [clickedPlay, setClickedPlay] = useState(false);
    const [loginPrompt, setLoginPrompt] = useState<GameMode | null>(null);

    const activeQueue = matchmakingState.activeQueue;
    const requestedQueue = matchmakingState.requestedQueue;

    const requestedAnyQueue = !!requestedQueue;
    const requestedThisQueue = (
        (gameType && requestedQueue?.gameType === gameType)
        || (!gameType && requestedAnyQueue)
    );

    const anyQueueActive = !!activeQueue;
    const inThisQueue = (
        (gameType && activeQueue?.queueType?.gameType === gameType)
        || (!gameType && anyQueueActive)
    );
    const inRankedQueue = (inThisQueue && activeQueue?.queueType?.gameMode === GameMode.RANKED);

    const buttonState: "play" | "options" | "cancel" = (
        requestedThisQueue ? "cancel" : (clickedPlay ? "options" : "play")
    );

    let errorMessage: string | null = null;
    if (matchmakingState.error) {
        errorMessage = matchmakingState.error.message;
    }

    let waitingDetails = "";
    let nonWaitingDetails = "";
    let highlightNonWaitingDetails = false;
    if (requestedQueue && requestedThisQueue) {
        if (matchmakingState.connected) {
            if (activeQueue && areQueueTypesEqual(activeQueue.queueType, requestedQueue)) {
                nonWaitingDetails = `Searching for a ${inRankedQueue ? "ranked" : "casual"} game`;
                highlightNonWaitingDetails = true;
            } else {
                nonWaitingDetails = "Joining queue";
            }
        } else {
            nonWaitingDetails = "Connecting";
        }
    } else {
        if (waitingRankedCount > 0) {
            waitingDetails += `${waitingRankedCount}\xa0waiting\xa0(ranked)`;
        }
        if (waitingCasualCount > 0) {
            if (waitingDetails !== "") {
                waitingDetails += ", ";
            }
            waitingDetails += `${waitingCasualCount}\xa0waiting\xa0(casual)`;
        }
        if (gameType === null || playingCount > 0) {
            if (waitingDetails !== "") {
                waitingDetails += ", ";
            }
            nonWaitingDetails += `${playingCount}\xa0playing`;
        }
        if (gameType === null) {
            nonWaitingDetails += " online";
        }
    }

    const joinRankedQueue = useCallback(() => startTransition(() => {
        if (!user)
            throw new Error("An account is required to join the ranked queue");

        matchmakingState.setRequestedQueue({
            gameMode: GameMode.RANKED,
            gameType: gameType ?? GameType.FINKEL,
        });
        setClickedPlay(false);
    }), [matchmakingState, gameType, setClickedPlay, user]);

    const joinCasualQueue = useCallback(() => startTransition(() => {
        matchmakingState.setRequestedQueue({
            gameMode: GameMode.ONLINE,
            gameType: gameType ?? GameType.FINKEL,
        });
        setClickedPlay(false);
    }), [matchmakingState, gameType, setClickedPlay]);

    return (
        <div className={cn(
            styles.entry,
            gameType === null && styles.small,
        )}>
            {gameType && (
                <div className={styles.ruleset}>
                    <RuleSetPopupButton gameType={gameType} />
                </div>
            )}

            <div className={styles.details}>
                {!clickedPlay && (
                    <AnimatePresence>
                        {(waitingDetails || nonWaitingDetails) && (
                            <FadingDiv key="details" options={{ duration: 0.2 }}>
                                {errorMessage && (
                                    <p className={cn(
                                        "margin-0",
                                        styles.error,
                                    )}>
                                        {errorMessage}
                                    </p>
                                )}

                                {!errorMessage && (
                                    <p className="margin-0">
                                        {waitingDetails && (
                                            <span className={styles.waiting}>
                                                {waitingDetails}
                                            </span>
                                        )}
                                        {nonWaitingDetails && (
                                            <span className={cn(
                                                highlightNonWaitingDetails && styles.highlight,
                                            )}>
                                                {nonWaitingDetails}

                                                {requestedThisQueue && (
                                                    <DotDotDot enabled={true} />
                                                )}
                                            </span>
                                        )}
                                    </p>
                                )}
                            </FadingDiv>
                        )}
                    </AnimatePresence>
                )}
            </div>

            <div className={cn(
                styles.play_buttons,
                buttonState === "options" && styles.play_buttons__options,
            )}>

                {buttonState === "play" && (
                    <RoyalUrButton
                        size="tiny"
                        style={gameType ? "cta" : "secondary"}
                        className={gameType ? "width-100%" : ""}
                        onClick={() => {
                            if (gameType !== null) {
                                setClickedPlay(true);
                                return;
                            }

                            if (user) {
                                joinRankedQueue();
                            } else {
                                setLoginPrompt(GameMode.RANKED);
                            }
                        }}>

                        {gameType ? "Play" : "Play Online"}
                    </RoyalUrButton>
                )}

                {buttonState === "options" && (
                    <>
                        <RoyalUrButton
                            size="tiny"
                            style="cta"
                            title="Play a competitive rated game"
                            onClick={() => {
                                if (user) {
                                    joinRankedQueue();
                                } else {
                                    setLoginPrompt(GameMode.RANKED);
                                }
                            }}>

                            Ranked (Beta)
                        </RoyalUrButton>

                        <RoyalUrButton
                            size="tiny"
                            style="secondary"
                            title="Play a casual unrated game"
                            onClick={() => {
                                if (user) {
                                    joinCasualQueue();
                                } else {
                                    setLoginPrompt(GameMode.ONLINE);
                                }
                            }}>

                            Casual
                        </RoyalUrButton>

                        <RoyalUrButton
                            size="tiny"
                            style="cancel"
                            className={styles.cancel}
                            onClick={() => {
                                setClickedPlay(false);
                            }}>

                            <CloseIcon className="icon icon--lg" />
                        </RoyalUrButton>
                    </>
                )}

                {buttonState === "cancel" && (
                    <RoyalUrButton
                        size="tiny"
                        style="cancel"
                        className={styles.wide_cancel}
                        onClick={() => startTransition(() => {
                            setClickedPlay(false);
                            matchmakingState.setRequestedQueue(null);
                        })}>

                        <CloseIcon className="icon icon--lg" />
                    </RoyalUrButton>
                )}
            </div>

            {!user && (
                <>
                    <LoginPrompt
                        visible={!!loginPrompt}
                        close={() => setLoginPrompt(null)}
                        onNoThanks={() => startTransition(() => {
                            setLoginPrompt(null);
                            if (loginPrompt === GameMode.ONLINE) {
                                joinCasualQueue();
                            }
                        })}
                        title={
                            loginPrompt === GameMode.RANKED
                                ? "You need an account to play ranked"
                                : undefined
                        }
                        noThanksText={
                            loginPrompt === GameMode.RANKED
                                ? "Cancel"
                                : "Play without an account"
                        }>

                        <p>
                            {loginPrompt === GameMode.ONLINE && (
                                <>
                                    Online games are better when you&apos;re not anonymous!
                                </>
                            )}

                            {loginPrompt === GameMode.RANKED && (
                                <>
                                    You can still play casual games, or games against the computer,
                                    without an account.
                                </>
                            )}
                        </p>
                    </LoginPrompt>
                </>
            )}
        </div>
    );
}


interface PendingLobbiesListProps {
    matchmakingState: MatchmakingState;
    small?: boolean;
}


function PendingLobbiesList({
    matchmakingState, small,
}: PendingLobbiesListProps) {
    const finkelWaiting = matchmakingState.getWaitingPlayerCounts(GameType.FINKEL);
    const blitzWaiting = matchmakingState.getWaitingPlayerCounts(GameType.BLITZ);
    const mastersWaiting = matchmakingState.getWaitingPlayerCounts(GameType.MASTERS);
    const rankedWaiting = (finkelWaiting.ranked + blitzWaiting.ranked + mastersWaiting.ranked);
    const casualWaiting = (finkelWaiting.casual + blitzWaiting.casual + mastersWaiting.casual);
    return (
        <>
            {!small && (
                <>
                    <PendingLobbyListEntry
                        gameType={GameType.FINKEL}
                        waitingRankedCount={finkelWaiting.ranked}
                        waitingCasualCount={finkelWaiting.casual}
                        playingCount={matchmakingState.activeLobbyCounts.finkelCount}
                        matchmakingState={matchmakingState} />

                    <PendingLobbyListEntry
                        gameType={GameType.BLITZ}
                        waitingRankedCount={blitzWaiting.ranked}
                        waitingCasualCount={blitzWaiting.casual}
                        playingCount={matchmakingState.activeLobbyCounts.blitzCount}
                        matchmakingState={matchmakingState} />

                    <PendingLobbyListEntry
                        gameType={GameType.MASTERS}
                        waitingRankedCount={mastersWaiting.ranked}
                        waitingCasualCount={mastersWaiting.casual}
                        playingCount={matchmakingState.activeLobbyCounts.mastersCount}
                        matchmakingState={matchmakingState} />
                </>
            )}

            {small && (
                <PendingLobbyListEntry
                    gameType={null}
                    waitingRankedCount={rankedWaiting}
                    waitingCasualCount={casualWaiting}
                    playingCount={matchmakingState.activeLobbyCounts.totalCount}
                    matchmakingState={matchmakingState} />
            )}

            <p className={cn(
                styles.create_link,
                small && styles.small,
            )}>
                <Link href={new Path(
                    "/game",
                    (
                        small
                            ? { mode: "friend", rules: "finkel", tab: "play" }
                            : { mode: "friend", tab: "rules" }
                    )
                ).toString()}>
                    Or, create a link to send to a friend!
                </Link>
            </p>
        </>
    );
}


interface MatchmakingControlsProps {
    className?: string;
    clientControls: GameClientControls;
    getWatchLink: (lobbyID: string) => Path;
    small?: boolean;
}


export function MatchmakingControls({
    className, clientControls, getWatchLink, small,
}: MatchmakingControlsProps) {
    const [rawTab, setTab] = useState<"play" | "watch">("play");
    const tab = (small ? "play" : rawTab);

    const {
        lobbyManagerState,
        lobbyManagerStateLoading,
        lobbyManagerStateError,
    } = useLobbyManagerState();

    const waitingPlayers: WaitingPlayerState[] = useMemo(() => {
        return lobbyManagerState?.waitingPlayers ?? [];
    }, [lobbyManagerState?.waitingPlayers]);

    const activeLobbies: LobbySummary[] = useMemo(() => {
        return lobbyManagerState?.startedLobbies ?? [];
    }, [lobbyManagerState?.startedLobbies]);

    const joinLobby = useCallback((lobbyID: string) => {
        const settings = new RemoteLobbySettings(lobbyID);
        clientControls.startGame(settings);
    }, [clientControls]);

    const {
        activeQueue,
        requestedQueue,
        setRequestedQueue,
        connected,
        error,
    } = useMatchmaking(joinLobby);

    const matchmakingState = useMemo(() => new MatchmakingState(
        activeQueue, requestedQueue, setRequestedQueue, connected, error, waitingPlayers, activeLobbies
    ), [activeQueue, requestedQueue, setRequestedQueue, connected, error, waitingPlayers, activeLobbies]);

    // Hack to stop players seeing themselves as waiting.
    useEffect(() => {
        const queueType = activeQueue?.queueType;
        if (queueType) {
            matchmakingState.minusOneWaiting(queueType.gameMode, queueType.gameType);
        }
    }, [matchmakingState, activeQueue]);

    let statusElem = null;
    if (!lobbyManagerState || lobbyManagerStateLoading || lobbyManagerStateError) {
        let status = "Loading live games...";
        if (lobbyManagerStateError) {
            status = `Error loading live games: ${lobbyManagerStateError.message}`;
            console.error(status);
        }
        if (!small) {
            statusElem = (
                <p>{status}</p>
            );
        } else {
            statusElem = (<></>);
        }
    }

    return (
        <div className={className}>
            <motion.div
                className={styles.live_lobbies_container}
                {...fadeInOut()}>

                {!small && (
                    <>
                        <div className={styles.tabs}>
                            <button
                                className={cn(
                                    styles.tab,
                                    tab === "play" && styles.tab_active,
                                )}
                                onClick={() => setTab("play")}>

                                Play a game
                            </button>

                            <button
                                className={cn(
                                    styles.tab,
                                    tab === "watch" && styles.tab_active,
                                )}
                                onClick={() => setTab("watch")}>

                                Watch a game
                            </button>
                        </div>
                    </>
                )}

                <div>
                    <AnimatePresence mode="wait">
                        {statusElem && (
                            <motion.div
                                className={cn(
                                    styles.entry_list,
                                    small && styles.small,
                                )}
                                {...fadeInOut({ delay: lobbyManagerStateLoading ? 0.25 : 0.0 })}>

                                {statusElem}
                            </motion.div>
                        )}

                        {!statusElem && tab === "play" && (
                            <motion.div
                                key="play"
                                className={cn(
                                    styles.entry_list,
                                    small && styles.small,
                                )}
                                {...fadeInOut()}>

                                <PendingLobbiesList
                                    matchmakingState={matchmakingState}
                                    small={small} />
                            </motion.div>
                        )}

                        {!statusElem && tab === "watch" && (
                            <motion.div
                                key="watch"
                                className={styles.entry_list}
                                {...fadeInOut()}>

                                {activeLobbies.length > 0 && (
                                    <LiveLobbiesEntryList
                                        summaries={activeLobbies}
                                        getWatchLink={getWatchLink} />
                                )}

                                {activeLobbies.length === 0 && (
                                    <p className="padding-sm">
                                        The boards are quiet right now
                                    </p>
                                )}
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>
            </motion.div>
        </div>
    );
}
