import { GameSource } from "@/ts/business/game/controller/source/GameSource";
import { RollGameEvent } from "@/ts/business/game/event/RollGameEvent";
import { MoveGameEvent } from "@/ts/business/game/event/MoveGameEvent";
import { PlayerType } from "@/ts/royalur/model/PlayerType";
import { GameMode } from "@/ts/business/game/GameMode";
import { GameUpdateEvent } from "@/ts/business/game/controller/source/GameUpdateEvent";
import { GamePlayers } from "@/ts/business/api/game/GamePlayers";
import { LobbySettings } from "@/ts/business/game/LobbySettings";
import { AnalyticsProvider } from "@/ts/business/analytics/Analytics";
import { MessageOutUploadGame } from "@/ts/business/api/game/MessageOutUploadGame";
import { RuleSet } from "@/ts/royalur/rules/RuleSet";
import { GameAPI } from "@/ts/business/api/GameAPI";
import { HumanLobbyPlayer } from "@/ts/business/lobby/HumanLobbyPlayer";
import { APIGamePreferences } from "@/ts/business/api/api_schema";
import { GameClientControls } from "@/ts/business/GameClientControls";
import { ReactionType } from "@/ts/business/game/ReactionType";
import { GameEndReason } from "@/ts/business/game/GameEndReason";
import { LocalLobbyStatus } from "@/ts/business/game/controller/status/LocalLobbyStatus";
import { LobbyID } from "@/ts/business/lobby/LobbyID";


export class LocalGameSource extends GameSource<LocalLobbyStatus> {
    constructor(
        initialClientControls: GameClientControls,
        initialPreferences: APIGamePreferences,
        analytics: AnalyticsProvider,
        gameAPI: GameAPI,
        setupSettings: LobbySettings,
        rules: RuleSet,
    ) {
        super(
            initialClientControls, initialPreferences, analytics, gameAPI, setupSettings,
            new LocalLobbyStatus(
                LobbyID.generateRandom(),
                GameMode.LOCAL, null, null, null, null
            ),
            rules,
        );

        this.handleGameSetup(null);

        this.game.subscribe((game) => {
            if (game.isFinished()) {
                analytics.recordFinishGame(setupSettings);
            }
        });
    }

    override getControllerMaxDirectives(): number {
        return 25;
    }

    override isLocalHumanPlayer(_playerType: PlayerType): boolean {
        return true;
    }

    private decideLeftPlayer(lastLeftPlayer: PlayerType | null): PlayerType {
        // Make it more likely that the player type will swap back and forth.
        const threshold = (
            lastLeftPlayer === null ? 0.5
                : (lastLeftPlayer === PlayerType.LIGHT ? 0.75 : 0.25)
        );
        return Math.random() < threshold ? PlayerType.DARK : PlayerType.LIGHT;
    }

    private handleGameSetup(lastLeftPlayer: PlayerType | null): void {
        const game = this.getRules().generateGame();
        const leftPlayer = this.decideLeftPlayer(lastLeftPlayer);
        this.game.set(game, new GameUpdateEvent(true));

        const existingPlayers = this.players.get();
        const lightPlayer = new HumanLobbyPlayer(
            null, existingPlayers.getLightPlayerData(), null,
        );
        const darkPlayer = new HumanLobbyPlayer(
            null, existingPlayers.getDarkPlayerData(), null,
        );
        this.players.set(new GamePlayers(
            leftPlayer, null,
            lightPlayer, darkPlayer,
        ));
        this.lobbyStatus.map(status => status.withCompleteGameID(null));
        this.getAnalytics().recordStartGame(this.getSetupSettings());
    }

    override handleResign(): void {
        const newGame = this.game.get().copy();
        const player = newGame.getTurn();
        newGame.resign(player);

        // We don't want to send the game update event
        // until we've updated the lobby status as well.
        const sendGameEvent = this.game.setAndDeferEvent(
            newGame, new GameUpdateEvent(false),
        );

        this.lobbyStatus.map(status => status.withEnd(
            GameEndReason.RESIGNATION, player
        ));
        sendGameEvent();
    }

    override setup(): () => void {
        return () => {};
    }

    override handleMove(event: MoveGameEvent): void {
        const game = this.game.get();
        if (game.isWaitingForMove()) {
            game.move(event.getMove());
            this.game.set(game, new GameUpdateEvent(event.getSkipAnimation()));
        }
    }

    override handleRoll(_event: RollGameEvent): void {
        const game = this.game.get();
        if (game.isWaitingForRoll()) {
            game.rollDice();
            this.game.set(game, new GameUpdateEvent(false));
        }
    }

    override handleReaction(player: PlayerType | null, reaction: ReactionType) {
        this.triggerReaction(player, reaction);
    }

    override shouldUploadGame(): boolean {
        return true;
    }

    override buildUploadGameMessage(): MessageOutUploadGame {
        const game = this.game.get();
        if (!game.isFinished())
            throw new Error("The game is not finished");

        const players = this.players.get();
        const status = this.lobbyStatus.get();
        return new MessageOutUploadGame(
            status.getLobbyID().get(),
            status.getGameMode(),
            players.getPlayer1(),
            players.getPlayer2(),
            game,
        );
    }
}
