import { PacketIn } from "@/ts/business/game/server/inbound/PacketIn";
import { InboundPacketType } from "@/ts/business/game/server/inbound/InboundPacketType";
import { MessageIOContext } from "@/ts/business/api/MessageIOContext";
import { QueueType, readJsonQueueType } from "@/ts/business/game/queue/QueueType";


export class PacketInRemovedFromQueue extends PacketIn {
    public queueType: QueueType | null = null;

    constructor() {
        super(InboundPacketType.REMOVED_FROM_QUEUE);
    }

    getQueueType(): QueueType {
        if (this.queueType === null)
            throw new Error("Packet has not been read yet");

        return this.queueType;
    }

    override readContents(
        _context: MessageIOContext,
        json: Record<string, any>,
    ): void {
        this.queueType = readJsonQueueType(json);
    }
}
