import { GameMode } from "@/ts/business/game/GameMode";
import { GameType } from "@/ts/business/game/GameType";
import { readJsonString } from "@/ts/util/json";


export interface QueueType {
    gameMode: GameMode;
    gameType: GameType;
}


export function areQueueTypesEqual(type1: QueueType, type2: QueueType): boolean {
    return (type1.gameMode === type2.gameMode && type1.gameType === type2.gameType);
}


export function readJsonQueueType(json: Record<string, any>): QueueType {
    const gameMode: GameMode = GameMode.getByID(readJsonString(json, "queue_game_mode"));
    const gameType: GameType = GameType.getByID(readJsonString(json, "queue_game_type"));
    return { gameMode, gameType };
}
