
export class InboundPacketType {
    // General.
    public static readonly PONG: InboundPacketType = new InboundPacketType("pong");
    public static readonly ERROR: InboundPacketType = new InboundPacketType("error");
    public static readonly SET_ID: InboundPacketType = new InboundPacketType("set_id");

    // Matchmaking.
    public static readonly QUEUE_STATE: InboundPacketType = new InboundPacketType("queue_state");
    public static readonly REMOVED_FROM_QUEUE: InboundPacketType = new InboundPacketType("removed_from_queue");

    // Lobbies.
    public static readonly JOIN_LOBBY: InboundPacketType = new InboundPacketType("join_lobby");
    public static readonly UNKNOWN_LOBBY: InboundPacketType = new InboundPacketType("unknown_lobby");
    public static readonly LOBBY_STATUS: InboundPacketType = new InboundPacketType("lobby_status");

    // Games.
    public static readonly PLAYER_DATA: InboundPacketType = new InboundPacketType("player_data");
    public static readonly GAME: InboundPacketType = new InboundPacketType("game");
    public static readonly REACTION: InboundPacketType = new InboundPacketType("reaction");

    static values(): InboundPacketType[] {
        return [
            InboundPacketType.PONG,
            InboundPacketType.ERROR,
            InboundPacketType.SET_ID,

            InboundPacketType.QUEUE_STATE,
            InboundPacketType.REMOVED_FROM_QUEUE,

            InboundPacketType.JOIN_LOBBY,
            InboundPacketType.UNKNOWN_LOBBY,
            InboundPacketType.LOBBY_STATUS,

            InboundPacketType.PLAYER_DATA,
            InboundPacketType.GAME,
            InboundPacketType.REACTION,
        ];
    }

    private readonly name: string;

    constructor(name: string) {
        this.name = name;
    }

    getName(): string {
        return this.name;
    }

    static getByName(name: string): InboundPacketType {
        for (const type of InboundPacketType.values()) {
            if (type.getName() === name)
                return type;
        }
        throw new Error("Unknown inbound packet type: " + name);
    }
}
