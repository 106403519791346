"use client";

import { useEffect } from "react";
import { usePathname } from "next/navigation";


declare global {
    interface Window {
        __uspapi?: (...args: any[]) => void;
        __tcfapiLocator?: (...args: any[]) => void;
        __tcfapi?: (...args: any[]) => void;
    }
}


export function addAnalyticsConsentListener(callback: (consented: boolean) => void, tries?: number) {
    if (window.__tcfapi) {
        window.__tcfapi(
            "addEventListener",
            2,
            (tcData: any, success: boolean) => {
                if (success) {
                    callback(!!(
                        tcData?.purpose?.consents[8]
                        || tcData?.purpose?.legitimateInterests[8]
                    ));
                }
            },
        );

    } else if (!tries || tries < 10) {
        setTimeout(() => addAnalyticsConsentListener(callback, (tries ?? 1) + 1), 1000);
    }
}


export function MoneytizerCMP() {
    const pathname = usePathname();
    const provideCMP = (pathname === "/" || pathname.startsWith("/game"));

    // Load the CMP banner.
    useEffect(() => {
        if (!provideCMP)
            return;

        const existing = document.getElementById("inmobi-cmp");
        if (existing)
            return;

        const script = document.createElement("script");
        script.id = "inmobi-cmp";
        script.async = true;
        script.type = "text/javascript";
        script.src = "https://cmp.inmobi.com/choice/6Fv0cGNfc_bw8/www.themoneytizer.com/choice.js?tag_version=V3";

        const firstScript = document.getElementsByTagName("script")[0];
        if (firstScript?.parentNode) {
            firstScript.parentNode.insertBefore(script, firstScript);
        } else {
            document.head.appendChild(script);
        }
    }, [provideCMP]);

    // Compatibility.
    useEffect(() => {
        if (!provideCMP)
            return;

        let uspTries = 0;
        const uspTriesLimit = 3;
        let uspIntervalID: NodeJS.Timeout | undefined = undefined;

        const TCF_LOCATOR_NAME = "__tcfapiLocator";

        let gdprApplies: boolean | undefined = undefined;

        const makeStub = () => {
            const queue: any[] = [];
            let win: Window = window;
            let cmpFrame;

            function addFrame() {
                const doc = win.document;
                const otherCMP = !!(win.frames[TCF_LOCATOR_NAME]);

                if (!otherCMP) {
                    if (doc.body) {
                        const iframe = doc.createElement("iframe");
                        iframe.style.cssText = "display: none";
                        iframe.name = TCF_LOCATOR_NAME;
                        doc.body.appendChild(iframe);
                    } else {
                        setTimeout(addFrame, 5);
                    }
                }
                return !otherCMP;
            }

            const tcfAPIHandler = (...args: any[]) => {
                if (!args.length) {
                    return queue;
                } else if (args[0] === "setGdprApplies") {
                    if (
                        args.length > 3
                        && args[2] === 2
                        && typeof args[3] === "boolean"
                    ) {
                        gdprApplies = args[3];
                        if (typeof args[2] === "function") {
                            args[2]("set", true);
                        }
                    }
                } else if (args[0] === "ping") {
                    const retr = {
                        gdprApplies: gdprApplies,
                        cmpLoaded: false,
                        cmpStatus: "stub",
                    };
                    if (typeof args[2] === "function") {
                        args[2](retr);
                    }
                } else {
                    if (args[0] === "init" && typeof args[3] === "object") {
                        args[3] = Object.assign(args[3], {
                            tag_version: "V3",
                        });
                    }
                    queue.push(args);
                }
            };

            const postMessageEventHandler = (event: any) => {
                const msgIsString = typeof event.data === "string";
                let json: any = {};

                try {
                    if (msgIsString) {
                        json = JSON.parse(event.data);
                    } else {
                        json = event.data;
                    }
                } catch (_ignore) { /* ignored */ }

                const payload = json.__tcfapiCall;
                if (payload && window.__tcfapi) {
                    window.__tcfapi(
                        payload.command,
                        payload.version,
                        (retValue: any, success: any) => {
                            let returnMsg: any = {
                                __tcfapiReturn: {
                                    returnValue: retValue,
                                    success: success,
                                    callId: payload.callId,
                                },
                            };
                            if (msgIsString) {
                                returnMsg = JSON.stringify(returnMsg);
                            }
                            if (event && event.source && event.source.postMessage) {
                                event.source.postMessage(returnMsg, "*");
                            }
                        },
                        payload.parameter
                    );
                }
            };

            while (win) {
                try {
                    if (win.frames[TCF_LOCATOR_NAME]) {
                        cmpFrame = win;
                        break;
                    }
                } catch (_ignore) { /* ignored */ }

                if (win === window.top)
                    break;

                win = win.parent;
            }
            if (!cmpFrame) {
                addFrame();
                win.__tcfapi = tcfAPIHandler;
                win.addEventListener("message", postMessageEventHandler, false);
            }
        };

        makeStub();

        const uspStubFunction = (...args: any[]) => {
            if (typeof window.__uspapi !== "undefined") {
                setTimeout(() => {
                    if (typeof window.__uspapi !== "undefined") {
                        window.__uspapi.apply(window.__uspapi, args);
                    }
                }, 500);
            }
        };

        const checkIfUspIsReady = () => {
            uspTries++;
            if (window.__uspapi === uspStubFunction && uspTries < uspTriesLimit) {
                console.warn("USP is not accessible");
            } else {
                clearInterval(uspIntervalID);
            }
        };

        if (typeof window.__uspapi === "undefined") {
            window.__uspapi = uspStubFunction;
            uspIntervalID = setInterval(checkIfUspIsReady, 6000);
        }

        return () => {
            clearInterval(uspIntervalID);
        };
    }, [provideCMP]);

    return (<></>);
}
